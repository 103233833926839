import React from "react";
import OneTrip from "./OneTrip.js";
import "./OurTrips.scss";


const ListOfTrips = (props) => {
  
    // const promotedTrip = props.List.filter(el => el.OfferId === "174")

    return (
        <div id="listOfTrips">
            <div className="container">
            <h1>Rejsy morskie w sezonie 2025/26</h1>
                {/* <OneTrip List={promotedTrip} isPromotedTrip /> */}
                <OneTrip List={props.List}  />
            </div>
        </div>
    )
}



export default ListOfTrips