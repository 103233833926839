import React from "react";
import "./Blog.scss";
import Loader from "./Loader.js";
import { fetchPostBySlug, fetchData } from "./store/actions.js";
import {connect} from "react-redux";
import {getBlogItemCachedUrlName, getBlogItemPageCachedUrlName} from "./cachedStoreUrls";
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

const prefetchMethod = (dispatch, slug) => {
  const blogItemPageCachedUrlName = getBlogItemPageCachedUrlName(slug);
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: slug,
      },
      blogItemPageCachedUrlName
    )
  );
};

const getPostById = async (dispatch, slug) => {
  const blogItemCachedUrl = getBlogItemCachedUrlName(slug)
  return dispatch(
    await fetchPostBySlug(
      slug,
      blogItemCachedUrl
    )
  );
};

const enhance = connect((state, props) => {
  const slug = props.match.params.id;
  const blogItemCachedUrl = getBlogItemCachedUrlName(slug)
  const blogItemPageCachedUrlName = getBlogItemPageCachedUrlName(slug)
  return {
    Page: state.fetchedUrls[blogItemPageCachedUrlName]?.Page || {},
    data: state.fetchedUrls[blogItemCachedUrl],
    dataLoaded: state.urlStatus[blogItemCachedUrl] === "done",
    dataPageLoaded: state.urlStatus[blogItemPageCachedUrlName] === "done",
  };
});

class Blog extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const id = this.props.match.params.id;
    prefetchMethod(this.props.dispatch, id)
    getPostById(this.props.dispatch, id);
  };

  render() {
    const { Page, dataPageLoaded,  dataLoaded, data } = this.props;
    const metaData = Page?.META;

    if (!dataLoaded || !dataPageLoaded) {
      return (
        <Loader />
      );
    }
    return (
      <div className="blogContainer">
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta name="description" content={metaData?.Description} data-react-helmet="true" />
        </Helmet>
        <div className="content">
        <Link id="blogList" to={"/blog"}>  Wróć</Link>
          <h1 dangerouslySetInnerHTML={{__html: data?.title}} />
          <div className="blogDate">{new Date(data?.date).toLocaleDateString()}</div>
          <div dangerouslySetInnerHTML={{__html: data?.content}} />
        </div>
      </div>
    );
  }
}

Blog.prefetchMethod = prefetchMethod;

export default enhance(Blog);
