import React from "react";
import "./Contact.scss";
import "./ContactPage.scss";
import PartnersWrapper from "./PartnersWrapper.js";
import {Link} from "react-router-dom";
import Location from "./Location.js";
import politykaPrywatnosci from "./userRegistration/documents/polityka-prywatnosci.pdf";
import regulaminTaksidi from "./userRegistration/documents/regulamin-taksidi.pdf";
import ReactHtmlParser from "react-html-parser";
import {connect} from "react-redux";
import {contactPageCachedUrl} from "./cachedStoreUrls";
import {fetchData} from "./store/actions";
import APIbackend from "./config.js";
import {Helmet} from "react-helmet";

export const Contact = ({
  Page,
  handleChange,
  email,
  handleSubmit,
  truemessage,
  errormessage,
  findHTMLPageHTML,
  handleClick,
}) => {
  return (
    <div id="contactPage">
      <div className="wrapper">
        <div className="wrapperInfo">
          <div className="box">
            {Page?.Contents && ReactHtmlParser(findHTMLPageHTML[0].Contents.HTML)}
            <Link to="./faq" title="Sprawdź pełne FAQ">
              <button className="secondaryBtn" onClick={handleClick}>
                Sprawdź FAQ
              </button>
            </Link>
            <div className="partnersInfo" style={{marginBottom: "20px"}}>
              <p>
                <a
                  href={regulaminTaksidi}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Przeczytaj Regulamin strony"
                >
                  Regulamin strony<i className="arrow"></i>
                </a>
              </p>
              <p>
                <a
                  href={politykaPrywatnosci}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Polityka prywatności The Boat Trip"
                >
                  Polityka prywatności<i className="arrow"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="wrapperForm">
          <h1>Napisz do nas</h1>
          <div className="form">
            <form>
              <label>Twój adres e-mail</label>
              <input
                name="email"
                type="email"
                placeholder="Twój adres e-mail"
                onChange={handleChange}
                value={email}
              ></input>
              <label>Treść wiadomości</label>
              <textarea
                name="contents"
                onChange={handleChange}
                placeholder="W czym możemy Ci pomóc?"
                rows="8"
                cols="50"
              ></textarea>
              <button type="submit" className="primaryBtn" onClick={handleSubmit} title="Wyślij wiadomość">
                Wyślij wiadomość
              </button>
              <p>
                <span className="trueMessage">{truemessage} </span>
                <span style={{fontSize: "14px"}} className="errorMessage">
                  {errormessage}
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: "contactPage",
      },
      contactPageCachedUrl
    )
  );
};
const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls[contactPageCachedUrl]?.Page || {},
    dataLoaded: state.urlStatus[contactPageCachedUrl] === "done",
  };
});
class ContactPage extends React.Component {
  state = {
    email: "",
    truemessage: "",
    errormessage: "",
    contents: "",
    loading: true,
  };

  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    var data = {
      siteId: 1,
      email: this.state.email,
      contents: this.state.contents,
    };

    fetch(`${APIbackend}/v1/contact/request`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      response.json().then(() => {
        if (response.status !== 200) {
          this.setState({
            errormessage: "Uzupełnij wszystkie pola",
            truemessage: "",
          });
        }

        if (response.status === 200) {
          this.setState({
            errormessage: "",
            truemessage: "Wiadomość została wysłana",
          });
        }
      });
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {Page, dataLoaded} = this.props;
    const {errormessage, email, truemessage, contents} = this.state;
    const {handleChange, handleSubmit} = this;
    const findHTMLPageHTML = Page?.Contents ? Page?.Contents?.filter(({Contents}) => Contents.HTML) : null;
    const metaData = Page?.META;

    if (!dataLoaded) {
      return (
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }
    return (
      <>
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta name="description" content={metaData?.Description} data-react-helmet="true" />
        </Helmet>
        <Contact
          findHTMLPageHTML={findHTMLPageHTML}
          Page={Page}
          errormessage={errormessage}
          handleChange={handleChange}
          truemessage={truemessage}
          email={email}
          handleSubmit={handleSubmit}
          contents={contents}
        />
        <Location
          findHTMLPageHTML={findHTMLPageHTML}
          Page={Page}
          errormessage={errormessage}
          handleChange={handleChange}
          truemessage={truemessage}
          email={email}
          handleSubmit={handleSubmit}
          contents={contents}
        />
        <PartnersWrapper />
      </>
    );
  }
}

ContactPage.prefetchMethod = prefetchMethod;

export default enhance(ContactPage);
