import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./OfferFaq.scss";

const FaqlArr = [
  {
    question: "1. Nigdy nie byłem na jachcie. Czy muszę coś umieć, żeby pojechać na rejs?",
    answer:
      "Większość osób, które z nami pływa nigdy nie miała wcześniej do czynienia z jachtami. Rejsy po ciepłych morzach są dostosowane dla każdego, a doświadczony skipper zapewni bezpieczeństwo podczas rejsu, a także pokaże wszystkim chętnym o co w tym calym żeglarstwie chodzi. Do tego, zeby się opalać, popijać drinki i rozkoszować delikatną bryzą, a na daystopach kąpać w morzu nie trzeba mieć żadnych uprawnień :)",
  },
  {
    question: "2. Czy będę cierpieć na chorobę morską?",
    answer:
      "Choroba morska jest niepotrzebnie demonizowana, wiele osób w ogóle jej nie doświadcza. Aczkolwiek zdarzają się przypadki, że na odcinkach pomiędzy wyspami, gdzie może wystąpić delikatna fala, niektórzy odczuwają dyskomfort. Współczesne środki na chorobę lokomocyjną są doskonałym na to remedium. Dodatkowo większość naszych rejsów odbywa się po osłoniętych od większej fali akwenach, a dziennie przeloty są dosyć krótkie, więc mało kto na naszych rejsach cierpi na chorobę morską.",
  },
  {
    question: "3. Jaki jest przedział wiekowy uczestników?",
    answer:
      "Większość naszych rejsów - te ze wstążkami PARTY / EXPLORE / ADVENTURE / CHILL to rejsy dla młodych osób, w wieku 20-40 lat. Staramy się, żeby załogi były jednorodne wiekowo, dzięki czemu lepiej się dogadują, mają więcej wspólnych tematów, a doświadczenie z takiego urlopu jest dla wszystkich przyjemniejsze. Jeśli ktoś jest starszy niż ten przedział, zapraszamy również - mamy też rejsy w kategorii OPEN i FAMILY - one są otwarte dla wszystkich i tam nie ma żadnego limitu wiekowego.",
  },
  {
    question: "4. Jak się żyje na jachcie? Gdzie można się umyć, skorzystać z toalety itp.?",
    answer:
      "Na jachcie jest zawsze 4-6 kabin (w zależności od wielkości), które stanowią ekwiwalent normalnych małych pokoików z łóżkiem,szafkami itd. Dodatkowo do dyspozycji załogi są 2-4 łazienki - nieco mniejsze wersje standardowych łazienek hotelowych, gdzie można się normalnie wyprostować, z toaletą, prysznicem z gorącą wodą i normalną spłukiwaną toaletą. Suszarkę można podłączyć do normalnego gniazdka na jachcie, jednak tylko, gdy stoi w porcie lub gdy jacht jest wyposażony w generator.",
  },
  {
    question: "5. Chcę dołączyć do rejsu solo, czy jest taka opcja?",
    answer: "Oczywiście, że tak! Załogi dobieramy w taki sposób, aby były podobne wiekowo oraz zróżnicowane płciowo. Bardzo dużo naszych klientów wybiera się na wyjazdy solo i z doświadczenia wiemy, że takie załogi dogadują się zawsze najlepiej, a poznane na rejsie osoby stanowią często przyjaźnie na całe życie :)",
  },
  {
    question: "6. Jak wygląda kwestia wyżywienia na jachcie i czym jest zrzutka na burtowe?",
    answer: "Cena rejsu nie obejmuje wyżywienia. Na jachtach znajdziecie w pełni wyposażoną kuchnię, gdzie najczęściej załogi same przygotowują posiłki - jest to bardzo wygodne. Najczęściej na początku rejsu załogi robią zrzutkę na podstawowe produkty żywieniowe - tzw zrzutka na BURTOWE, a potem dzielą się na wachty (zmiany) - tak, żeby danego dnia wyżywienie załogi było w gestii konkretnej wachty. Oprócz tego oczywiście na trasie rejsu będzie możliwość wyjść do knajp - to jak często decydujecie się jeść na jachcie, a jak często wychodzić - zależy tylko od Was. Z naszego doświadczenia - system zrzutki na burtowe i podziału na wachty sprawdza się najlepiej, a optymalna jest kombinacja gotowania na jachcie + 2/3 wspólne wyjścia załogowe do knajp podczas rejsu. WAZNE - pamiętajcie, że w zrzutce na burtowe nie uczestniczy skipper, jego wyżywienie zwyczajowo leży w gestii załogi. Więcej o tym temacie, a także nasza estymacja wysokości burtowego w zależności od kraju znajdziecie w pełnym FAQ'u dostępnym pod linkiem poniżej.",
  },
];

const FaqList = (props) => {
  return (
    <Accordion allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{props.question}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{props.answer}</AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

const OfferFaq = () => {
  return (
    <div id="offerFaqWrapper">
      <div class="titleWrapper">
        <h2>Najczęściej zadawane pytania</h2>
      </div>
      {FaqlArr.map(({question, answer}, index) => (
        <FaqList key={index} question={question} answer={answer} />
      ))}
      <div className="buttonWrapper">
        <a href="/faq" title="Sprawdź pełny FAQ The Boat Trip">
          <button id="button" class="primaryBtn">
            Pełny FAQ
          </button>
        </a>
        <p>Pamiętaj, że w przypadku pytań zawsze możesz się z nami skontaktować telefonicznie lub mailowo.</p>
      </div>
    </div>
  );
};

export default OfferFaq;
