import React, { useEffect, Component } from 'react';
import LoginReservation from './LoginReservation.js';
// import FamilyTrips from "./FamilyTrips.js";
import StepSubmitPayU from './StepSubmitPayU.js';
import StepSubmitPayUError from './StepSubmitPayUError.js';
import SailAndParty from './SailAndParty.js';
import { Redirect } from 'react-router-dom';
import './App.css';
import MenuPage from './MenuPage.js';
import Footer from './Footer.js';
// import PanelMenu from "./userRegistration/PanelMenu.js";
import Reset from './userRegistration/Reset.js';
import Registration from './userRegistration/Registration.js';
import RegistrationConfirm from './userRegistration/RegistrationConfirm.js';
import Login from './userRegistration/Login.js';
import Discounts from './Discounts.js';
import SpecialPeriod from './SpecialPeriod.js';
import { Helmet } from 'react-helmet';
import APIbackend from './config.js';

// import ReservationTrip from "./ReservationTrip.js";
import URLS from './urls';
import NewPassword from './userRegistration/NewPassword.js';
import TagManager from 'react-gtm-module';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { IAmForAnalitics } from './utilsApp';
import ScrollArrow from './ScrollToTop.js';
import ROUTES from './Routes';

import { Route, Switch, useLocation, withRouter } from 'react-router-dom';

const tagManagerArgs = {
  gtmId: 'GTM-MXVFC6F',
};

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    global.window?.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);
const Tracker = withRouter(IAmForAnalitics);

class App extends Component {
  state = {
    // loading: true,
    ledBoxRed: '',
    isAmbassador: '',
    userLogged: false,
    firstName: '',
    buttons: true,
    stepsNumber: true,
    email: '',
    password: '',
    incorrectLoginMessage: '',
    emailIncorrect: '',
    passwordIncorrect: '',
    loginActive: true,
    type: 'password',
    error: null,
    redirectRegistration: false,
    redirectReset: false,
    reservation: false,
    userPanelActive: false,
    elementMenuName: '',
    elementMenu: '',
    hasNewMessages: false,
  };

  handleReservation = () => {
    this.setState({
      reservation: true,
    });
  };

  componentDidMount = () => {
    // this.fakeRequest().then(() => {
    //   const el = document.querySelector(".lds-roller");
    //   if (el) {

    //     this.setState({ loading: false }); // showing the app
    //   }
    // });

    TagManager.initialize(tagManagerArgs);

    fetch(`${APIbackend}/v1/auth/details`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          this.setState({
            isAmbassador: data.Auth.IsAmbassador,
            hasNewMessages: data.Auth.HasNewMessages,
          });

          fetch(`${APIbackend}/v1/account/details`, {
            method: 'GET',
            credentials: 'include',
          }).then((response) => {
            response.json().then((data) => {
              this.setState({
                elementMenuName:
                  data.Account.FirstName !== null &&
                  data.Account.FirstName !== ''
                    ? 'Witaj, ' + data.Account.FirstName
                    : 'Witaj',
              });
              setTimeout(() => {
                this.setState({
                  ledBoxRed:
                    this.state.hasNewMessages === false &&
                    data.Account.FirstName !== null &&
                    data.Account.FirstName !== ''
                      ? 'led-red'
                      : null,
                });
              }, 100);
            });
          });
          this.setState({
            elementMenu: '/twoj-panel',
            userLogged: true,
            reservation: true,
          });
        } else if (response.status !== 200) {
          this.setState({
            elementMenuName: 'zaloguj',
            elementMenu: '/zaloguj',
          });
        }
      });
    });

  };
  // fakeRequest = () => {
  //   return new Promise(resolve => setTimeout(() => resolve(), 2500));
  // };

  handleLoginClick = (event) => {
    event.preventDefault();

    var data = {
      email: this.state.email,
      password: this.state.password,
    };

    fetch(`${APIbackend}/v1/auth/login`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
    }).then((response) => {
      response.json().then((data) => {
        if (response.status !== 200) {
          this.setState({
            loginActive: true,
            incorrectLoginMessage: 'Niepoprawny login lub hasło',
            buttons: false,
            stepsNumber: false,
          });
        }

        if (response.status === 200) {
          fetch(`${APIbackend}/v1/account/details`, {
            method: 'GET',
            credentials: 'include',
          }).then((response) => {
            response.json().then((data) => {
              this.setState({
                elementMenuName:
                  data.Account.FirstName !== null &&
                  data.Account.FirstName !== ''
                    ? 'Witaj, ' + data.Account.FirstName
                    : 'Witaj',
              });
            });
          });

          this.setState({
            reservation: true,
            loginActive: false,
            // elementMenuName: "Twój Panel" + this.state.firstName,
            elementMenu: 'twoj-panel',
            userPanelActive: true,
            buttons: true,
            stepsNumber: true,
            userLogged: true,
          });
        }
      });
    });
  };

  // handleLoginClickReservation = (event) => {
  //   event.preventDefault()

  //   var data = {
  //     "email": this.state.email,
  //     "password": this.state.password,
  //   };

  //   fetch('${APIbackend}/v1/auth/login', {

  //     method: "POST",
  //     credentials: "include",
  //     body: JSON.stringify(data),

  //   })

  //     .then((response) => {
  //       response.json()
  //         .then((data) => {

  //           if (response.status !== 200) {
  //             this.setState({
  //               loginActive: true,
  //               incorrectLoginMessage: "Niepoprawny login lub hasło",
  //               buttons: false,
  //               stepsNumber: false,
  //             })
  //           }

  //           if (response.status === 200) {
  //             this.setState({
  //               reservation: true,
  //               loginActive: false,
  //               elementMenuName: "Twój panel",
  //               elementMenu: "twoj-panel",
  //               userPanelActive: true,
  //               buttons: true,
  //               stepsNumber: true,
  //             })
  //           }
  //         })
  //     }
  //     )
  // };

  handleClick = () => {
    this.setState(({ type }) => ({
      type: type === 'password' ? 'text' : 'password',
    }));
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {
      email,
      password,
      incorrectLoginMessage,
      error,
      emailIncorrect,
      passwordIncorrect,
      userPanelActive,
      loginActive,
      reservation,
      type,
    } = this.state;
    const { handleChange, handleClick, handleLoginClick } = this;

    return (
      // <>
      //   {this.state.loading ?
      //     <div class="lds-roller">
      //       <div></div>
      //       <div></div>
      //       <div></div>
      //       <div></div>
      //       <div></div>
      //       <div></div>
      //       <div></div>
      //       <div></div>
      //     </div> :
      <>
        <Helmet>
          <title>The Boat Trip</title>
          <meta
            name="description"
            content="Rejsy Morskie: Chorwacja, Grecja, Włochy, Czarnogóra, Karaiby, Seszele, Tajlandia. Na 100% Najlepsze Wakacje Na Jachcie. Sprawdź!"
            data-react-helmet="true"
          />
          <meta
            name="keywords"
            content="wycieczka, egzotyka, Chorwacja, skipper, rejsy, morskie, Czarnogór, Tajlandia, Karaiby, Seszeele, Baleary, Ibiza, Włochy "
            data-react-helmet="true"
          />
        </Helmet>
        <Tracker />
        <ScrollToTop>
          <MenuPage
            elementMenuName={this.state.elementMenuName}
            ledBoxRed={this.state.ledBoxRed}
            elementMenu={this.state.elementMenu}
            hasNewMessages={this.state.hasNewMessages}
          />
          <div id="appContainer">
            <Switch>
              {global.window?.location.href ===
              `${global.window?.location.origin}/OnlinePayment` ? (
                <Route path="/OnlinePayment">
                  <Redirect to={{ pathname: '/OnlinePayment' }} />
                  <StepSubmitPayU />
                </Route>
              ) : null}

              {global.window?.location.href.includes('error') && (
                <Route path="/OnlinePayment">
                  <Redirect
                    to={{ pathname: '/OnlinePayment', search: '?error' }}
                  />
                  <StepSubmitPayUError />
                </Route>
              )}

              {global.window?.location.href ===
                `${global.window?.location.origin}/?paymentGenericError` &&
                global.window?.location.replace(
                  `${global.window?.location.origin}/OnlinePayment/?error`
                )}

              {ROUTES.map(({ path, component, exact }) => (
                <Route
                  key={path}
                  exact={exact}
                  path={path}
                  component={component}
                />
              ))}

              {/* <Route path="/wyjazdy-rodzinne" component={FamilyTrips} /> */}

              <Route path="/znizki" component={Discounts} />
              <Route path="/partnerzy" component={Discounts} />
              <Route path={URLS.Special} component={SpecialPeriod} />
              <Route path="/majowka" component={SpecialPeriod} />
              {/* <Route exact path="/zaloguj" render={() => <Login email={email}
                  password={password}
                  incorrectLoginMessage={incorrectLoginMessage}
                  emailIncorrect={emailIncorrect}
                  passwordIncorrect={passwordIncorrect}
                  loginActive={loginActive}
                  userPanelActive={userPanelActive}
                  type={type}
                  error={error}
                  handleChange={handleChange}
                  handleClick={handleClick}
                  handleLoginClick={handleLoginClick} />} /> */}
              {/* <Route exact path="/rezerwacja" render={(props) => <ReservationTrip {...props} email={email}
                  password={password}
                  // userLogged={this.state.userLogged}
                  elementMenu={this.state.elementMenu}
                  incorrectLoginMessage={incorrectLoginMessage}
                  emailIncorrect={emailIncorrect}
                  passwordIncorrect={passwordIncorrect}
                  loginActive={loginActive}
                  reservation={reservation}
                  buttons={this.state.buttons}
                  stepsNumber={this.state.stepsNumber}
                  type={type}
                  error={error}
                  handleChange={handleChange}
                  handleClick={handleClick}
                  handleLoginClick={handleLoginClick} />}
                /> */}

              <Route
                exact
                path="/rezerwacja"
                render={(props) => (
                  <LoginReservation
                    {...props}
                    email={email}
                    password={password}
                    handleReservation={this.handleReservation}
                    elementMenu={this.state.elementMenu}
                    userLogged={this.state.userLogged}
                    incorrectLoginMessage={incorrectLoginMessage}
                    emailIncorrect={emailIncorrect}
                    passwordIncorrect={passwordIncorrect}
                    loginActive={loginActive}
                    reservation={reservation}
                    buttons={this.state.buttons}
                    stepsNumber={this.state.stepsNumber}
                    handleClick={handleClick}
                    type={type}
                    error={error}
                    handleChange={handleChange}
                    handleLoginClick={handleLoginClick}
                  />
                )}
              />
            </Switch>

            <Switch>
              <Route
                exact
                path="/zaloguj"
                render={() => (
                  <Login
                    email={email}
                    password={password}
                    incorrectLoginMessage={incorrectLoginMessage}
                    emailIncorrect={emailIncorrect}
                    passwordIncorrect={passwordIncorrect}
                    loginActive={loginActive}
                    userPanelActive={userPanelActive}
                    type={type}
                    error={error}
                    handleChange={handleChange}
                    handleClick={handleClick}
                    handleLoginClick={handleLoginClick}
                  />
                )}
              />
              <Route path="/reset-hasła" component={Reset} />
              <Route path={['/rejestracja']} component={Registration} />
              <Route
                path={['/rejestracja', '/Activate/:token']}
                component={RegistrationConfirm}
              />
              {/* <Route path="/twoj-panel" render={() => <PanelMenu isAmbassador={this.state.isAmbassador} />} /> */}
              <Route path="/Password/:token" component={NewPassword} />
            </Switch>

            {/* <Switch>
                <Route path="/promocje" component={Discounts} />
                <Route path="/zniżki" component={Discounts} />
                <Route path="/partnerzy" component={Discounts} />
              </Switch>  */}

            <Switch>
              <Route path="/sailandparty" component={SailAndParty} />
              {/* <Route path="/sailandexplore" component={SailAndExplore} /> */}
            </Switch>
          </div>
          <Footer />
        </ScrollToTop>
        <MessengerCustomerChat
          pageId="152587614806587"
          appId="472674707432829"
          htmlRef={global.window?.location.pathname}
          minimized={true}
          loggedInGreeting="Cześć, potrzebujesz pomocy? Napisz do nas!"
          loggedOutGreeting="Cześć, potrzebujesz pomocy? Napisz do nas!"
          autoLogAppEvents={true}
          xfbml={true}
          language="pl"
        />

        <ScrollArrow />
      </>
      //     }
      // </>
    );
  }
}

export default App;
