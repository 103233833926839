import React from "react";
import "./SpecialPeriod.scss";
import "./userRegistration/Trips.scss";
// import ReactHtmlParser from 'react-html-parser';
import "./FilteredTrips.scss";
import Moment from "moment";
import { Link } from "react-router-dom";
import { linkToOfferId } from "./urls";
import calendarIcon from "./img/calendar2.png";
// import imageCover from "./img/1.png";
// import imageSpecialPeriod from "./img/image1-SpecialPeriod.jpg";
// import imageSpecialPeriodTwo from "./img/image2-SpecialPeriod.jpg";
// import imageYahtOne from "./img/yacht.png";
// import imageBeachTwo from "./img/beach.png";
// import imageMonumentThree from "./img/monument.png";
import APIbackend from "./config.js";
import { Helmet } from "react-helmet";

const FilteredTrips = (props) => {
  props.List.sort((a, b) =>
    a.Availability.From.split("-")
      .join()
      .localeCompare(b.Availability.From.split("-").join())
  );
  return (
    <div id="filteredTrips" style={{ paddingTop: "40px" }}>
      <h2>Rejsy w maju</h2>
      <div className="container">
        {props.List.map((el) => {
          const regionImg = el.Region.Country.Image?.url;
          const typeImg = el.Type.Image;
          return el.Availability.Available &&
            el.Availability.GrayedOut === false ? (
            <div>
              <Link to={linkToOfferId(el.OfferId)}>
                <div id="oneTripFiltered">
                  <div className="photoContainer">
                    <Link to={linkToOfferId(el.OfferId)}>
                      <img src={el.ListImg?.url} alt={el.ListImg?.alt} />
                      <div className="overlay"></div>
                    </Link>

                    <div className="tripInfo">
                      <h3>
                        <Link to={linkToOfferId(el.OfferId)}>{el.Name}</Link>
                      </h3>
                      <div className="infoTripBox">
                        <p>
                          <img src={regionImg?.url} alt={regionImg?.alt} />
                          {el.Region.Country.Name}, {el.Region.Name}
                        </p>

                        <p>
                          <img src={calendarIcon} alt="Termin rejsu" />
                          {Moment(el.Availability.From).format("DD.MM")} -{" "}
                          {Moment(el.Availability.To).format("DD.MM.YYYY")}
                          {el.RibbonTextExt === "Wyjazd potwierdzony" ? (
                            <span className="confirmedTrip">
                              {el.RibbonTextExt}
                            </span>
                          ) : (
                            <span>{el.RibbonTextExt}</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="tagsBox">
                      <div className="oneTag">
                        <img src={typeImg?.url} alt={typeImg?.alt} />{" "}
                        {el.Type.Name}
                      </div>
                    </div>

                    <div className="priceSection">
                      <div className="priceBox">
                        {el.Prices.Strikethrough === null ? (
                          <div className="regularPrice">
                            <p className="price">{el.Prices.Base} PLN</p>
                            <p className="text">Cena od osoby</p>
                          </div>
                        ) : (
                          <>
                            <div className="discount">
                              <p className="newPrice">{el.Prices.Base} PLN</p>
                              <p className="text">Promocyjna cena od osoby</p>
                            </div>
                            <p className="price">
                              {el.Prices.Strikethrough} PLN
                            </p>
                            <p className="text">Cena od osoby</p>
                          </>
                        )}
                      </div>
                      <div class="detailsBox">
                        <p className="amountOfPeople">
                          Liczba miejsc: {el.Rooms.AllocationsListCount}
                        </p>
                        {el.RibbonText === "Ostatnie wolne miejsca" ||
                        el.RibbonText === "Ostatnie miejsca" ? (
                          <p className="availability red">{el.RibbonText}</p>
                        ) : (
                          <p className="availability">{el.RibbonText}</p>
                        )}
                        <button className="primaryBtn">
                          <Link to={linkToOfferId(el.OfferId)} title="Zobacz szczegóły oferty">
                            Zobacz szczegóły
                          </Link>
                        </button>
                      </div>
                    </div>

                    {/* <div className="shortInfo">{ReactHtmlParser(el.Info.Short)}</div> */}
                  </div>
                </div>
              </Link>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

class SpecialPeriod extends React.Component {
  state = {
    siteId: 1,
    filterTerm: [7],
    List: [],
  };

  componentDidMount = () => {
    var data = {
      siteId: this.state.siteId,
    };

    fetch(`${APIbackend}/v1/offers/list`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
        filterTerm: this.state.filterTerm,
        filterCountry: [],
        filterType: [],
        filterOptions: [],
      }),
    })
      .then((response) => response.json())
      .then((data) => this.setState({ List: data.List }));

    fetch(`${APIbackend}/v1/offers/filters`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      response.json().then((data) => {
        this.setState({
          Types: data.Types,
          Countries: data.Countries,
          Terms: data.Terms,
          Options: data.Options,
        });
      });
    });
  };

  render() {
    return (
      <div id="specialPeriod">
        <Helmet>
          <title>Majówka 2022 - oferta Rejsów</title>
          <meta
            name="description"
            content="Organizujemy dobrze zaplanowane rejsy, w różne zakątki świata. Znajdziesz tu informacje dotyczące naszych aktualnych ofert na rejsy w maju. Sprawdź co dla Was przygotowaliśmy!"
            data-react-helmet="true"
          />
          <meta
            name="keywords"
            content="majówka, rejsy, morski, Polska"
            data-react-helmet="true"
          />
        </Helmet>
        {/* <div className="slider">
                    <img src={imageCover} alt={""} />
                    <h2>Majówka</h2>
                </div> */}
        <div className="info">
          {/* <div className="container">
                        <div className="firstSection">

                            <div className="imageSection">
                                <img src={imageSpecialPeriod} alt={""} />
                            </div>
                            <div className="textSection">
                                <h2>Majówka</h2>
                                <p>The Boat Trip to nowoczesne podejście do żeglowania, rejsy zaprojektowane i dostępne dla młodych ludzi.
                                To tydzień pływania, zabawy, zwiedzania i chilloutu w najpiękniejszych miejscach na całym swiecie,
                                    z flotą od 10 do 25 nowoczesnych jachtów pełnych młodych, energicznych ludzi takich jak Ty.</p>
                            </div>
                        </div>

                        <div className="secondSection">
                            <div className="textSection">
                                <h2>Urlop na statku</h2>
                                <p>Rejs z nami to wakacje, w których mógłby wziąć udział każdy!
                                Na jachcie będzie nasz profesjonalny, doświadczony sternik,
                                który zajmie się wszystkim. Nie wymagamy od Ciebie żadnych umiejętności
                                żeglarskich - oczekujemy jedynie, żebyście potrafili się doskonale bawić!</p>

                            </div>
                            <div className="imageSection">
                                <img className="firstPhoto" src={imageSpecialPeriodTwo} alt={""} />
                            </div>
                        </div>
                    </div>
                    <div className="tags">
                        <div className="oneTag">
                            <img src={imageYahtOne} alt={""}></img>
                            <p className="title">Flota ok. 10-15 jachtów i totalny chillout</p>
                        </div>
                        <div className="oneTag">
                            <img src={imageBeachTwo} alt={""}></img>
                            <p className="title">Młodzi ludzie, ciepły klimat i przepiękne widoki</p>
                        </div>
                        <div className="oneTag">
                            <img src={imageMonumentThree} alt={""}></img>
                            <p className="title">Świetne miejscówki i zabytkowe miasteczka</p>
                        </div>
                    </div> */}
          <FilteredTrips List={this.state.List} />
        </div>
      </div>
    );
  }
}

export default SpecialPeriod;
