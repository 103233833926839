import React from "react";
import "./ChangePassword.scss";
import APIbackend from "../config.js";

class ChangePassword extends React.Component {
  state = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    typeOldPassword: "password",
    typeNewPassword: "password",
    typeNewPasswordRepeat: "password",
    errorMessageOld: "",
    errorMessageNew: "",
    errorMessageStyleNew: "",
    errorMessageStyleOld: "",
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
    };

    const {newPassword, newPasswordRepeat} = this.state;
    if (newPassword !== newPasswordRepeat) {
      this.setState({
        errorMessageNew: "Hasła muszą być zgodne",
        errorMessageStyleNew: "errorMessageInput",
      });
    } else {
      this.setState({
        errorMessageNew: "",
        errorMessageStyleNew: "",
      });
    }

    if (newPassword === newPasswordRepeat) {
      fetch(`${APIbackend}/v1/account/passwordChange`, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
      }).then((response) =>
        response.json().then((responseData) => {
          <>
            <>
              {responseData.details &&
                this.setState({
                  errorMessageOld: "Wypełnij prawidłowo wszystkie pola",
                  errorMessageStyleOld: "errorMessageInput",
                })}
            </>
            <>
              {response.status === 200 &&
                this.setState({
                  errorMessageOld: "",
                  errorMessageStyleOld: "",
                  oldPassword: "",
                  newPassword: "",
                  newPasswordRepeat: "",
                  trueMessage: "Twoje hasło zostało zmienione",
                })}
            </>
          </>;
        })
      );
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClickOldPass = () =>
    this.setState(({typeOldPassword}) => ({
      typeOldPassword: typeOldPassword === "password" ? "text" : "password",
    }));

  handleClickNewPass = () =>
    this.setState(({typeNewPassword}) => ({
      typeNewPassword: typeNewPassword === "password" ? "text" : "password",
    }));

  handleClickNewPassRepeat = () =>
    this.setState(({typeNewPasswordRepeat}) => ({
      typeNewPasswordRepeat: typeNewPasswordRepeat === "password" ? "text" : "password",
    }));

  render() {
    const {passwordActive} = this.props;
    const {handleChange, handleClickOldPass, handleClickNewPass, handleClickNewPassRepeat} = this;
    const {
      errorMessageStyleOld,
      oldPassword,
      typeOldPassword,
      typeNewPassword,
      newPassword,
      errorMessageStyleNew,
      errorMessageNew,
      errorMessageOld,
      newPasswordRepeat,
      typeNewPasswordRepeat,
      trueMessage,
    } = this.state;

    return (
      passwordActive && (
        <div id="changePassword">
          <h2>Zmień hasło</h2>
          <div className="form">
            <form noValidate>
              <p>Stare hasło</p>
              <div className="passwordBox">
                <input
                  name="oldPassword"
                  onChange={handleChange}
                  type={typeOldPassword}
                  value={oldPassword}
                  placeholder="Wpisz stare hasło"
                  className={errorMessageStyleOld}
                ></input>
                <span onClick={handleClickOldPass}>
                  {typeOldPassword === "password" ? <i class="fas fa-eye-slash"></i> : <i class="fas fa-eye"></i>}
                </span>
              </div>
              <p>Nowe hasło</p>
              <div className="passwordBox">
                <input
                  name="newPassword"
                  onChange={handleChange}
                  type={typeNewPassword}
                  value={newPassword}
                  placeholder="Wpisz nowe hasło"
                  className={errorMessageStyleNew}
                ></input>
                <span onClick={handleClickNewPass}>
                  {typeNewPassword === "password" ? <i class="fas fa-eye-slash"></i> : <i class="fas fa-eye"></i>}
                </span>
              </div>
              <p>Powtórz nowe hasło</p>
              <div className="passwordBox">
                <input
                  name="newPasswordRepeat"
                  onChange={handleChange}
                  type={typeNewPasswordRepeat}
                  value={newPasswordRepeat}
                  placeholder="Powtórz nowe hasło"
                  className={errorMessageStyleNew}
                ></input>
                <span onClick={handleClickNewPassRepeat}>
                  {typeNewPasswordRepeat === "password" ? <i class="fas fa-eye-slash"></i> : <i class="fas fa-eye"></i>}
                </span>
              </div>
              <div className="errorMessage">
                {errorMessageOld} {errorMessageNew}
              </div>
              <div className="trueMessage">{trueMessage}</div>
            </form>
            <button className="primaryBtn" onClick={this.handleSubmit}>
              Zmień hasło
            </button>
          </div>
        </div>
      )
    );
  }
}

export default ChangePassword;
