import React from "react";
import "./Destination.scss";
import Destination from "./Destination.js";
import { Link } from "react-router-dom";
import grecjaImage from "./mainPage/img/image-greece.jpg";
import croatiaImage from "./mainPage/img/image-croatia.jpg";
import mneImage from "./mainPage/img/image-mne.jpg";
import thailandImage from "./mainPage/img/image-thailand.jpg";
import carraibenImage from "./mainPage/img/image-carraiben.jpg";

var DestinationArr = [
  {
    index: 1,
    image: grecjaImage,
    alt: "Rejsy morskie - Grecja",
    country_name: "Grecja",
    number_of_offers: 3,
    filterCountry: 2,
  },

  {
    index: 2,
    image: croatiaImage,
    country_name: "Chorwacja",
    alt: "Rejsy morskie - Chorwacja",
    number_of_offers: 2,
    filterCountry: 1,
  },

  {
    index: 3,
    image: mneImage,
    country_name: "Czarnogóra",
    alt: "Rejsy morskie - Czarnogóra",
    number_of_offers: 2,
    filterCountry: 4,
  },

  {
    index: 4,
    image: thailandImage,
    country_name: "Tajlandia",
    alt: "Rejsy morskie - Tajlandia",
    number_of_offers: 2,
    filterCountry: 9,
  },

  {
    index: 5,
    image: carraibenImage,
    country_name: "Karaiby",
    alt: "Rejsy morskie - Karaiby",
    number_of_offers: 2,
    filterCountry: 12,
  },
];

DestinationArr.sort(function (a, b) {
  return parseInt(a.index) - parseInt(b.index);
});

const DestinationWrapper = () => {
  return (
    <div id="destinationContainer">
      <div className="content">
        <div className="mainText">
          <h2>Rejsy morskie - gdzie pływamy?</h2>
        </div>
        <div className="destinationWrapper">
          {DestinationArr.map(
            (
              {
                filterCountry,
                text,
                alt,
                image,
                country_name,
                number_of_offers,
              },
              index
            ) => (
              <Destination
                key={index}
                filterCountry={filterCountry}
                text={text}
                image={image}
                country_name={country_name}
                number={number_of_offers}
                alt={alt}
              />
            )
          )}
          <div className="oneDestination">
            <div className="text">
              <h3>... i wiele więcej</h3>
              <p style={{ margin: "0px 10px 60px" }}>
                Przeżyj niezapomniany tydzień Żegluj, Imprezuj, Zwiedzaj,
                Wypoczywaj! Sprawdź wszystkie dostępne destynacje w sezonie
                2025.
              </p>
              <Link to={{ pathname: "/rejsy-morskie" }} title="Sprawdź inne rejsy morskie">
                <button className="button primaryBtn">
                  Sprawdź inne rejsy morskie
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationWrapper;
