import React from "react";
import "./Accomodation.scss";
import "./Transport.scss";
import ReactHtmlParser from 'react-html-parser';

const TransportWrapper = ({
  reservationArr,
  nameParticipant,
  transportDetails,
  transportButton,
  userNumParticipant,
  userNum,
  journeyConfig,
  parameters,
  errorMessageParametersGetTransport,
  colorMessage,
  firstMessage,
  secondMessage,
  transportMessage,
  handleAllowedTransport,
  handleCancelTransport,
  handleChangeTransport,
  handleChangeTransportTown,
  handleSubmitParametersTransport,
}) => {
  const transportPlacement = reservationArr?.Placements ? Object?.values(reservationArr?.Placements) : [];
  return (
    <div id="transport">
      {reservationArr.ReserveList && (
        <div className="orangeBox">
          <p>
            Twoja rezerwacja jest na liście rezerwowej. Rezerwację potwierdzimy telefonicznie lub mailowo - prosimy do
            tego momentu nie wpłacać zaliczki.
          </p>
        </div>
      )}
      {!reservationArr.IsHistorical && reservationArr.Owner && !reservationArr.ReserveList && (
        <div className="greenBox">
          <p className="infoText">
            <i class="fas fa-unlock"></i> Zmiana danych jest możliwa do 2 tygodni przed wyjazdem
          </p>
          <p>
            Do 2 tygodni przed wyjazdem macie możliwość samodzielnej edycji danych. Następnie ta opcja jest blokowana i
            wszelkie zmiany oraz prośby możecie zgłaszać na info@theboattrip.eu
          </p>
        </div>
      )}

      {reservationArr.StepDetails.Step2[1].map((el, index) => {
        const transportMessage = transportPlacement?.[index]?.JourneyPlacement?.Message;
        const roomMessage = transportPlacement?.[index]?.JourneyPlacement?.RoomName;
        const pointMessage = transportPlacement?.[index]?.JourneyPlacement?.PointMessage;
        return (
          <div className="onePerson">
            <div className="name">
              <p>{nameParticipant[index]}</p>
            </div>
            <table className="detailsBox">
              <tr>
                <td className="firstColumn">{el[1].slice(0, 22)}</td>
                <td className="secondColumn">{el[1].slice(22, el[1].length)}</td>
              </tr>

              {el[2].slice(38, el[2].length).length !== 1 ? (
                <tr>
                  <td className="firstColumn">{el[2].slice(0, 38)}</td>
                  <td className="secondColumn">{el[2].slice(39, el[2].length)}</td>
                </tr>
              ) : null}
              {transportPlacement?.[index]?.JourneyPlacement && !(transportMessage === "" && roomMessage === "") && (
                <>
                  <h3>Informacja dojazdowa</h3>
                  {transportMessage !== "" && (
                    <tr>
                      <td className="firstColumn">Informacja:</td>
                      <td className="secondColumn">{ReactHtmlParser(transportMessage)}</td>
                    </tr>
                  )}
                  {roomMessage !== "" && (
                    <tr>
                      <td className="firstColumn">Rodzaj transportu:</td>
                      <td className="secondColumn">{roomMessage}</td>
                    </tr>
                  )}
                  {pointMessage !== "" && (
                    <tr>
                      <td className="firstColumn">Zbiórka:</td>
                      <td className="secondColumn">{ReactHtmlParser(pointMessage)}</td>
                    </tr>
                  )}
                </>
              )}
            </table>

            {reservationArr.ReserveList ||
              !reservationArr.Owner ||
              reservationArr.IsHistorical ||
              reservationArr.Status === "Anulowana" ||
              ((!transportDetails || transportButton || userNumParticipant[index] !== userNum) && (
                <div className="btnBox">
                  <button
                    className="primaryBtn"
                    onClick={(event) => handleAllowedTransport(userNumParticipant[index], event)}
                  >
                    Zmień opcje
                  </button>
                </div>
              ))}
            {userNumParticipant[index] === userNum && (
              <p style={{color: "#9c0d0d"}}>{errorMessageParametersGetTransport}</p>
            )}
            {transportDetails && userNumParticipant[index] === userNum && (
              <>
                <h4>Dojazd</h4>
                <div className="containerSelect">
                  <select onChange={(event) => handleChangeTransport(userNumParticipant[index], event)}>
                    <option className="placeholderList" selected="true" disabled="disabled">
                      Wybierz z listy
                    </option>
                    {parameters.JourneysList[1].map(
                      ({Journey}) =>
                        Journey.Available && (
                          <>
                            <option
                              name={userNum}
                              value={Journey.JourneyId}
                              selected={Journey.JourneyId === journeyConfig[index].journeyId}
                            >
                              {Journey.Name.includes("własny") || Journey.Name.includes("Własny")
                                ? "Dojazd Własny"
                                : Journey.Name}
                            </option>
                          </>
                        )
                    )}
                  </select>
                  <div className="btnBox" style={{display: "flex"}}>
                    <button
                      className="primaryBtn"
                      style={{margin: "auto 20px"}}
                      onClick={(event) => handleSubmitParametersTransport(journeyConfig[index].userNum, event)}
                    >
                      Zapisz zmiany
                    </button>
                    <button className="primaryBtn" style={{margin: "auto 20px"}} onClick={handleCancelTransport}>
                      Anuluj
                    </button>
                  </div>
                </div>
                {journeyConfig[index].journeyId === "1" && journeyConfig[index].userNum && (
                  <div style={{marginTop: "30px", marginLeft: "40px"}}>
                    <h4>Wybierz miejsce odbioru (wymagane)</h4>
                    {parameters.JourneysList[1].map(({Journey}) => (
                      <div className="addOption">
                        <form>
                          {Journey.PointsList.map(({Name, Price, JourneyPointId}) => (
                            <label className="containerInputCheckbox">
                              <p>
                                {Name} {Price === 0 || Price === "0" ? " " : "+" + Price + " PLN"}
                              </p>
                              <input
                                type="radio"
                                name={userNum}
                                checked={JourneyPointId === journeyConfig[index].journeyPointId}
                                onChange={(event) => handleChangeTransportTown(userNum, event)}
                                value={JourneyPointId}
                              ></input>
                              <span className="checkmark"></span>
                            </label>
                          ))}
                        </form>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {!reservationArr.IsHistorical &&
              reservationArr.Status !== "Anulowana" &&
              userNumParticipant[index] === userNum &&
              transportMessage && (
                <>
                  <p style={{color: colorMessage, marginLeft: "30px"}}>{firstMessage}</p>
                  <p style={{color: colorMessage, marginLeft: "30px"}}>{secondMessage}</p>
                </>
              )}
          </div>
        );
      })}
    </div>
  );
};

export default TransportWrapper;
