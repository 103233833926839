import ReactDOM from 'react-dom';
import React from 'react';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import store from './store/store.js';
import { Provider } from 'react-redux';

/* import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing'; */

/* Sentry.init({
    dsn: "https://0fda5c1141764eebb7fe4405481c5d5a@sentry.pgdev.xyz/2",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
}); */

ReactDOM.hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
