import React from "react";
import "./YourDetails.scss";
import DeleteAccount from "./DeleteAccount.js";
import "./DeleteAccount.scss";
import APIbackend from "../config.js";

class YourDetails extends React.Component {
  state = {
    buttonChangeDetails: true,
    firstName: "",
    lastName: "",
    birthDate: "",
    idDocument: "",
    country: "",
    gender: "",
    phone: "",
    email: "",
    invoiceName: "",
    invoiceNip: "",
    invoiceAddress: "",
    invoiceCity: "",
    invoiceZip: "",
    saveUpdateDetails: false,
    availableInput: true,
    message: "",
    colorMessage: "",
    selectedOption: "",
    showPopup: false,
  };

  handleChangeYourDetails = (event) => {
    event.preventDefault();
    this.setState({
      saveUpdateDetails: true,
      availableInput: false,
      buttonChangeDetails: false,
    });
  };

  handleCancelChangeDetails = (event) => {
    event.preventDefault();
    this.setState({
      saveUpdateDetails: false,
      availableInput: true,
      buttonChangeDetails: true,
    });
  };

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  onValueChange = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount = () => {
    fetch(`${APIbackend}/v1/account/details`, {
      method: "GET",
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        if (data.Account === undefined) {
          window.location.replace("https://theboattrip.eu/zaloguj");
        } else {
          const {
            FirstName,
            LastName,
            BirthDate,
            IdDocument,
            Country,
            Gender,
            Phone,
            Email,
            InvoiceName,
            InvoiceAddress,
            InvoiceCity,
            InvoiceZip,
            InvoiceNip,
          } = data.Account;
          this.setState({
            firstName: FirstName,
            lastName: LastName,
            birthDate: BirthDate,
            idDocument: IdDocument,
            country: Country,
            gender: Gender,
            phone: Phone,
            email: Email,
            invoiceName: InvoiceName,
            invoiceAddress: InvoiceAddress,
            invoiceCity: InvoiceCity,
            invoiceZip: InvoiceZip,
            invoiceNip: InvoiceNip,
            selectedOption: Gender,
          });
        }
      });
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      firstName,
      lastName,
      birthDate,
      idDocument,
      country,
      gender,
      phone,
      invoiceName,
      invoiceAddress,
      invoiceCity,
      invoiceZip,
      invoiceNip,
      email,
    } = this.state;
    var data = {
      firstName: firstName,
      lastName: lastName,
      birthDate: birthDate,
      idDocument: idDocument,
      country: country,
      gender: gender,
      phone: phone,
      invoiceName: invoiceName,
      invoiceAddress: invoiceAddress,
      invoiceCity: invoiceCity,
      invoiceZip: invoiceZip,
      invoiceNip: invoiceNip,
      email: email,
    };

    fetch(`${APIbackend}/v1/account/details/change`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) =>
      response.json().then(() => {
        if (response.status === 200) {
          this.setState(
            {
              message: "Twoje dane zostały zapisane poprawnie",
              colorMessage: "green",
              saveUpdateDetails: false,
              availableInput: true,
              buttonChangeDetails: true,
            },
            () =>
              setTimeout(() => {
                fetch(`${APIbackend}/v1/account/details`, {
                  method: "GET",
                  credentials: "include",
                }).then((response) => {
                  response.json().then((data) => {
                    const {
                      FirstName,
                      LastName,
                      BirthDate,
                      IdDocument,
                      Country,
                      Gender,
                      Phone,
                      InvoiceName,
                      InvoiceAddress,
                      InvoiceCity,
                      InvoiceZip,
                      InvoiceNip,
                    } = data.Account;
                    this.setState({
                      firstName: FirstName,
                      lastName: LastName,
                      birthDate: BirthDate,
                      idDocument: IdDocument,
                      country: Country,
                      gender: Gender,
                      phone: Phone,
                      invoiceName: InvoiceName,
                      invoiceAddress: InvoiceAddress,
                      invoiceCity: InvoiceCity,
                      invoiceZip: InvoiceZip,
                      invoiceNip: InvoiceNip,
                      selectedOption: Gender,
                      saveUpdateDetails: false,
                      availableInput: true,
                      buttonChangeDetails: true,
                    });
                  });
                });
              }, 500)
          );
        } else {
          this.setState({
            message: "Twoje dane nie zostały zapisane",
            colorMessage: "tomato",
          });
        }
      })
    );
  };

  render() {
    const {yourdetailsActive} = this.props;
    const {
      availableInput,
      firstName,
      lastName,
      gender,
      birthDate,
      invoiceCity,
      invoiceZip,
      invoiceAddress,
      invoiceNip,
      idDocument,
      country,
      phone,
      email,
      invoiceName,
      colorMessage,
      message,
      saveUpdateDetails,
      buttonChangeDetails,
      showPopup,
    } = this.state;
    const {handleChange, handleChangeYourDetails, handleCancelChangeDetails, handleSubmit, togglePopup} = this;
    return (
      yourdetailsActive && (
        <div id="yourDetails">
          <h2>Twoje dane</h2>
          <div className="form">
            <form noValidate>
              <p>Imię</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                name="firstName"
                onChange={handleChange}
                type="text"
                value={firstName}
              ></input>
              <p>Nazwisko</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                name="lastName"
                onChange={handleChange}
                type="text"
                value={lastName}
              ></input>
              <p>Data urodzenia</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                name="birthDate"
                onChange={handleChange}
                type="text"
                placeholder="YYYY-MM-DD"
                value={birthDate}
              ></input>
              <p>Seria i numer dowodu osobistego</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                onChange={handleChange}
                name="idDocument"
                type="text"
                value={idDocument}
              ></input>
              <p>Kraj</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                onChange={handleChange}
                name="country"
                type="text"
                value={country}
              ></input>
              <p>Pleć</p>
              <div className="containerInputRadio">
                <label className="inputRadio">
                  Kobieta
                  <input
                    disabled={availableInput ? "disabled" : ""}
                    type="radio"
                    name="result"
                    value="1"
                    onChange={this.onValueChange}
                    checked={gender === "1"}
                  ></input>
                  <span className="checkmark"></span>
                </label>
                <label className="inputRadio">
                  Mężczyzna
                  <input
                    disabled={availableInput ? "disabled" : ""}
                    type="radio"
                    name="result"
                    value="2"
                    onChange={this.onValueChange}
                    checked={gender === "2"}
                  ></input>
                  <span className="checkmark"></span>
                </label>
              </div>
              <p>Numer telefonu</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                name="phone"
                onChange={handleChange}
                type="text"
                value={phone}
              ></input>
              <p>E-mail</p>
              <input disabled name="email" value={email} type="email"></input>
              <h3 style={{textAlign: "left"}}>Dane do faktury</h3>
              <p>Nazwa firmy</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                name="invoiceName"
                onChange={handleChange}
                type="text"
                value={invoiceName}
              ></input>
              <p>NIP</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                name="invoiceNip"
                onChange={handleChange}
                type="text"
                value={invoiceNip}
              ></input>
              <p>Adres - ulica/numer:</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                name="invoiceAddress"
                onChange={handleChange}
                type="text"
                value={invoiceAddress}
              ></input>
              <p>Adres - miasto:</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                name="invoiceCity"
                onChange={handleChange}
                type="text"
                value={invoiceCity}
              ></input>
              <p>Adres - kod pocztowy:</p>
              <input
                disabled={availableInput ? "disabled" : ""}
                name="invoiceZip"
                onChange={handleChange}
                type="text"
                value={invoiceZip}
              ></input>
              <p style={{color: colorMessage}}>{message}</p>
              {buttonChangeDetails && (
                <div className="btnBox">
                  <button
                    style={{marginLeft: "0"}}
                    className="primaryBtn"
                    onClick={(event) => handleChangeYourDetails(event)}
                  >
                    Zmień dane
                  </button>
                </div>
              )}
              {saveUpdateDetails && (
                <div className="btnBox" style={{display: "flex"}}>
                  <button className="secondaryBtn" onClick={(event) => handleSubmit(event)}>
                    Zapisz zmiany
                  </button>
                  <button className="primaryBtn" onClick={(event) => handleCancelChangeDetails(event)}>
                    Anuluj
                  </button>
                </div>
              )}
            </form>
            <DeleteAccount showPopup={showPopup} togglePopup={togglePopup} />
          </div>
        </div>
      )
    );
  }
}

export default YourDetails;
