import {blogListCachedUrl} from '../cachedStoreUrls'
import APIbackend from '../config'

export const setUrlStatus = (url, status)=>{
    return {
        type: 'UPDATE_STATUS',
        url,
        status
    }
}

export const setDataFromServer = (url, body)=>{
    return {
        type: 'FETCH_DATA',
        url,
        body,
    }
}

export const setDataFromWordpress = (url, body)=>{
    return {
        type: 'FETCH_DATA',
        url,
        body,
    }
}

export const  fetchData = (urlSuffix, body = {}, memoUrl = '', ignoreCache = false)=> {
    return (dispatch, getState) => {

    const cachedUrlName = memoUrl || urlSuffix;

    const isDataFetched = getState().urlStatus[cachedUrlName] === 'done';
    const isDataFetching = getState().urlStatus[cachedUrlName] === 'pending';
    if((isDataFetched && ignoreCache === false) || isDataFetching){
        return null;
    }

    dispatch(setUrlStatus(cachedUrlName, 'pending'));
    return fetch(`${APIbackend}${urlSuffix}`, {
        method: "POST",
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            dispatch(setDataFromServer(cachedUrlName, data));
            dispatch(setUrlStatus(cachedUrlName, 'done'))
        }).catch((status)=>{
            dispatch(setUrlStatus(cachedUrlName, 'error'))
        });
  }
}

async function fetchAPI(query, { variables } = {}) {
    const headers = { 'Content-Type': 'application/json' }
  
    const res = await fetch('https://blog.theboattrip.eu/graphql', {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    })

  const json = await res.json()
  return json.data
}

const PAGE_LIMIT = 20;

export async function getAllPosts(after) {
    const afterVar = after ? `, after: "${after}"` : '';
    const data = await fetchAPI(`
    {
      posts(first: ${PAGE_LIMIT}${afterVar}) {
        edges {
          node {
            date
            featuredImage {
              node {
                sourceUrl
                altText
              }
            }
            excerpt
            title
            slug
          }
        }
        pageInfo {
            hasNextPage
            endCursor
        }
      }
    }
    `)
    return data?.posts
}

export async function getPostBySlug(slug) {
    const data = await fetchAPI(
      `
      query GetPostBySlug($slug: String!) {
        postBy(slug: $slug) {
          content
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
          excerpt
          date
          title
        }
      }`,
      {
        variables: { slug },
      }
    )
    return data.postBy
}

export const fetchPosts = async (urlSuffix, memoUrl = '', endCursor = '', ignoreCache = false)=> {
    return async (dispatch, getState) => {

    const cachedUrlName = memoUrl || urlSuffix;

    const isDataFetched = getState().urlStatus[cachedUrlName] === 'done';
    const isDataFetching = getState().urlStatus[cachedUrlName] === 'pending';
    if((isDataFetched && ignoreCache === false) || isDataFetching){
        return null;
    }


    dispatch(setUrlStatus(cachedUrlName, 'pending'));
    const response = await getAllPosts(endCursor).catch(()=>{
        dispatch(setUrlStatus(cachedUrlName, 'error'))
        throw new Error('Failed to fetch API')
    })

    const prevPosts = getState().fetchedUrls[blogListCachedUrl];
    const resp = endCursor ? {
        pageInfo: response.pageInfo,
        edges: [
            ...prevPosts.edges,
            ...response.edges,
        ]
    } : response;


    dispatch(setDataFromWordpress(cachedUrlName, resp));
    dispatch(setUrlStatus(cachedUrlName, 'done'))
  }
}

export const fetchPostBySlug = async (slug, memoUrl = '', ignoreCache = false)=> {
    return async (dispatch, getState) => {
        const cachedUrlName = memoUrl || slug;
    

    const isDataFetched = getState().urlStatus[cachedUrlName] === 'done';
    const isDataFetching = getState().urlStatus[cachedUrlName] === 'pending';
    if((isDataFetched && ignoreCache === false) || isDataFetching){
        return null;
    }

    dispatch(setUrlStatus(cachedUrlName, 'pending'));
    const response = await getPostBySlug(slug).catch(()=>{
        dispatch(setUrlStatus(cachedUrlName, 'error'))
        throw new Error('Failed to fetch API')
    })

    dispatch(setDataFromWordpress(cachedUrlName, response));
    dispatch(setUrlStatus(cachedUrlName, 'done'))
  }
}