import React from "react";
import "./Reset.scss";
import {Link} from "react-router-dom";
import APIbackend from "../config.js";

class Reset extends React.Component {
  state = {
    email: "",
    incorrectResetMessage: "",
    value: "",
    loginActive: false,
    registrationActive: false,
    resetActive: true,
    confirm: true,
    redirectRegistration: false,
    redirectLogin: false,
    newPassword: "",
    trueMessage: "",
    secondTrueMessage: "",
    errorMessage: "",
    errorMessage2: "",
    siteId: 1,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitReset = (event) => {
    event.preventDefault();
    fetch(`${APIbackend}/v1/auth/passwordReset`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
        email: this.state.email,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.status === 200) {
          this.setState({
            incorrectResetMessage: "",
            trueMessage:
              "Jeśli masz u nas konto i nie korzystałeś z resetu hasła w ciagu 24h, to otrzymasz email z dalszymi instrukcjami",
          });
        } else if (responseData.message === "User already authenticated") {
          this.setState({
            incorrectResetMessage: "Użytkownik jest już zalogowany",
            trueMessage: "",
          });
        } else if (response.status === 500) {
          this.setState({
            trueMessage: "Wprowadzony adres email jest nieprawidłowy",
          });
        }
      });
    });
  };

  render() {
    const {resetActive, email, trueMessage, incorrectResetMessage, secondTrueMessage} = this.state;
    const {handleChange, handleSubmitReset} = this;
    return (
      resetActive && (
        <div id="reset">
          <div className="container">
            <div className="firstPart">
              <div className="box">
                <h3>Resetuj hasło</h3>
                <p>
                  Jeśli nie pamiętasz hasła do swojego konta i chcesz je zresetować, wprowadź swój adres e-mail do pola
                  poniżej a następnie postępuj zgodnie z instrukcjami.
                </p>
                <div className="form">
                  <form noValidate>
                    <p>E-mail</p>
                    <input
                      name="email"
                      onChange={handleChange}
                      value={email}
                      type="email"
                      placeholder="Twój adres email"
                    ></input>
                    <div className="trueMessage">{trueMessage}</div>
                    <div className="errorMessage">{incorrectResetMessage}</div>
                    <div className="btnBox">
                      <button className="primaryBtn" onClick={handleSubmitReset}>
                        Resetuj hasło
                      </button>
                    </div>
                    <div className="linkPassword">
                      <Link to="/rejestracja" title="Utwórz nowe konto">
                        <span>
                          Utwórz nowe konto<i className="arrow"></i>
                        </span>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="trueMessage">{secondTrueMessage}</div>
            <div className="btnBox2">
              <Link to="/zaloguj" title="Wróć do logowania">
                <button className="secondaryBtn">Wróć do logowania</button>
              </Link>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default Reset;
