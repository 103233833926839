import React from 'react';
import './MenuWrapper.scss';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../img/logo-TBT.png';
import APIbackend from '../config.js';
import { fetchData } from '../store/actions';
import { connect } from 'react-redux';

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData('/v1/data/page', {
      siteId: 1,
      pageId: 'destinationList',
    })
  );
};
const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls?.['/v1/data/page']?.Page || {},
  };
});

class MenuWrapper extends React.Component {
  state = {
    elementMenu: '',
    elementMenuName: '',
    ledBoxRed: '',
    hasNewMessages: '',
    showedNav: '',
  };

  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);
    fetch(`${APIbackend}/v1/auth/details`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          this.setState({
            hasNewMessages: data.Auth.HasNewMessages,
          });

          fetch(`${APIbackend}/v1/account/details`, {
            method: 'GET',
            credentials: 'include',
          }).then((response) => {
            response.json().then((data) => {
              this.setState({
                elementMenuName:
                  data.Account.FirstName !== null &&
                  data.Account.FirstName !== ''
                    ? 'Witaj, ' + data.Account.FirstName
                    : 'Witaj',
                ledBoxRed:
                  data.Account.FirstName !== null &&
                  data.Account.FirstName !== '' &&
                  this.state.hasNewMessages &&
                  'led-red',
                elementMenu: 'Twoj-panel',
              });
            });
          });
        } else if (response.status !== 200) {
          this.setState({
            elementMenuName: 'zaloguj',
            elementMenu: 'zaloguj',
          });
        }
      });
    });
  };

  handleLogoutClick = () => {
    fetch(`${APIbackend}/v1/auth/logout`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      response.json().then(() => {
        if (response.status === 200) {
          window.location.href = '/';
        }
      });
    });
  };

  render() {
    const { Page } = this.props;
    const { elementMenuName, elementMenu, ledBoxRed } = this.state;
    const destinationListArr =
      Page?.Contents?.[0]?.Contents?.HTML.split('\r\n');
    const singleNavLinkArr = [
      { navLink: '/rejsy-morskie', navName: 'Rejsy' },
      { navLink: '/szkolenia', navName: 'Szkolenia' },
    ];

    const dropDownLinksArr = [
      {
        title: 'Czartery',
        navlinkContainer: [
          {navLink: '/rejsy-prywatne', navName: 'Rejsy szyte na miarę'},
          {navLink: '/rejsy-prywatne', navName: 'Czartery'}
        ]
      },
      {
        title: 'Destynacje',
        navlinkContainer: destinationListArr,
      },
      {
        title: 'Jak to działa',
        navlinkContainer: [
          {navLink: '/jak-to-dziala', navName: 'Rejs - o co tu chodzi?'},
          {navLink: '/faq', navName: 'FAQ'},
          {navLink: '/promocje', navName: 'Promocje'},

        ]
      },
      {
        title: 'O nas',
        navlinkContainer: [
          { navLink: '/o-nas', navName: 'Kim jesteśmy' },
          { navLink: '/o-nas/team', navName: 'Nasz zespół' },
          { navLink: '/o-nas/praca', navName: 'Praca' },
          { navLink: '/o-nas/dokumenty', navName: 'Dokumenty' },
          { navLink: '/blog', navName: 'Blog' },
          { navLink: '/kontakt', navName: 'Kontakt' }
        ],
      },
    ];

    return (
      <>
        <div id="menuWrapper">
          <div className="menu">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Navbar.Brand>
                <img
                  className="imageLogo"
                  src={logo}
                  alt="logo The Boat Trip"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  {singleNavLinkArr.map(({ navLink, navName }, index) => (
                    <LinkContainer to={navLink} key={index}>
                      <Nav.Link>{navName}</Nav.Link>
                    </LinkContainer>
                  ))}

                  {dropDownLinksArr.map(
                    ({ title, navlinkContainer }, index) => (
                      <NavDropdown
                        title={title}
                        id="collasible-nav-dropdown"
                        key={index}
                        onMouseOver={() => this.setState({
                          showedNav: title
                        })}
                        onMouseLeave={() => this.setState({
                          showedNav: ''
                        })}
                        show={this.state.showedNav === title}
                      >
                        {title === 'Destynacje'
                          ? navlinkContainer?.map((countryName) => (
                              <a
                                href={`/rejsy-morskie/${countryName.toLowerCase()}`}
                                className="dropdown-item"
                                title={`Rejsy morskie - ${countryName}`}
                              >
                                {countryName}
                              </a>
                            ))
                          : navlinkContainer.map(
                              ({ navLink, navName }, index) => (
                                <LinkContainer to={navLink} key={index}>
                                  <NavDropdown.Item>{navName}</NavDropdown.Item>
                                </LinkContainer>
                              )
                            )}
                      </NavDropdown>
                    )
                  )}
                  {elementMenuName === 'zaloguj' ? (
                    <LinkContainer to={elementMenu} id="linkLog">
                      <Nav.Link>{elementMenuName}</Nav.Link>
                    </LinkContainer>
                  ) : (
                    <>
                      <NavDropdown
                        title={elementMenuName}
                        id="collasible-nav-dropdown"
                      >
                        <LinkContainer to={elementMenu} id="linkLog">
                          <NavDropdown.Item>Twój Panel</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/Wyloguj">
                          <NavDropdown.Item onClick={this.handleLogoutClick}>
                            Wyloguj
                          </NavDropdown.Item>
                        </LinkContainer>
                      </NavDropdown>
                      <div className={ledBoxRed}></div>{' '}
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </>
    );
  }
}

MenuWrapper.prefetchMethod = prefetchMethod;

export default enhance(MenuWrapper);
