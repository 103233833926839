import React, { useEffect, useState, useMemo } from "react";
import "./TripFilter.scss";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ListOfTrips from "./ListOfTrips.js";
import APIbackend from "./config.js";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import queryString from "query-string";

const addToQuery = (router, key, value) => {
  const currentQuery = QueryObjectSerialize(router.query);

  if (currentQuery[key]) {
    currentQuery[key].push(value);
  } else {
    currentQuery[key] = [value];
  }
  router.push(
    `${router.pathname}${ObjectQueryToString(currentQuery)}`,
    undefined,
    { shallow: true }
  );
};

const removeFromQuery = (router, key, value) => {
  const currentQuery = QueryObjectSerialize(router.query);

  if (currentQuery[key]) {
    currentQuery[key] = currentQuery[key].filter((p) => p !== value);
    if (currentQuery[key].length === 0) {
      delete currentQuery[key];
    }
  }

  router.push(
    `${router.pathname}${ObjectQueryToString(currentQuery)}`,
    undefined,
    { shallow: true }
  );
};

const ObjectQueryToString = (queryObject) => {
  const keysArr = Object.keys(queryObject);

  if (keysArr.length === 0) return "";

  let result = "?";

  keysArr.forEach((key) => {
    result = result + `${key}=${queryObject[key]?.join(",")}&`;
  });

  return result;
};

export const QueryObjectSerialize = (queryObject) => {
  const newObjectQuery = { ...queryObject };

  Object.keys(newObjectQuery).forEach((key) => {
    newObjectQuery[key] = newObjectQuery[key].split(",");
  });

  return newObjectQuery;
};

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
}

export const TripFilter = ({ List, Types, Countries, Terms }) => {
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const [list, setList] = useState(List);
  const router = useRouter();
  const serializedQuery = QueryObjectSerialize(router.query);

  const handleCheckedDestination = (e) => {
    if (e.target.checked) {
      addToQuery(router, "destination", e.target.value);
    } else {
      removeFromQuery(router, "destination", e.target.value);
    }
  };

  const handleCheckedTypes = (e) => {
    if (e.target.checked) {
      addToQuery(router, "type", e.target.value);
    } else {
      removeFromQuery(router, "type", e.target.value);
    }
  };

  const handleCheckedTerms = (e) => {
    if (e.target.checked) {
      addToQuery(router, "term", e.target.value);
    } else {
      removeFromQuery(router, "term", e.target.value);
    }
  };

  useEffect(() => {
    const serializedQuery = QueryObjectSerialize(router.query);

    fetch(`${APIbackend}/v1/offers/list`, {
      method: "POST",
      body: JSON.stringify({
        siteId: parseInt("1"),
        filterType: serializedQuery["type"]
          ? serializedQuery["type"].map((el) => parseInt(el))
          : [],
        filterCountry: serializedQuery["destination"]
          ? serializedQuery["destination"].map((el) => parseInt(el))
          : [],
        filterTerm: serializedQuery["term"]
          ? serializedQuery["term"].map((el) => parseInt(el))
          : [],
        filterOptions: serializedQuery["option"]
          ? serializedQuery["option"].map((el) => parseInt(el))
          : [],
      }),
    })
      .then((result) => result.json())
      .then((data) => {
        setList(data.List);
      });
  }, [router.query]);

  const arrSort = list.sort((a, b) =>
    a.Availability.From.split("-")
      .join()
      .localeCompare(b.Availability.From.split("-").join())
  );
  const newArr = arrSort.filter(
    (el) =>
      el.Availability.Available === true &&
      el.Type.OfferTypeId !== "5" &&
      // el.Region.Country.CountryId !== "6" &&
      el.Region.Country.CountryId !== "11"
  );
  const listForFilters = List.sort((a, b) =>
    a.Availability.From.split("-")
      .join()
      .localeCompare(b.Availability.From.split("-").join())
  );
  const arrForFilters = listForFilters.filter(
    (el) =>
      el.Availability.Available === true &&
      el.Type.OfferTypeId !== "5" &&
      // el.Region.Country.CountryId !== "6" &&
      el.Region.Country.CountryId !== "11"
  );

  return (
    <>
      <div
        id="tripFilter"
        className={
          openMobileFilter
            ? "isVisible filteredTripsFixed"
            : "filteredTripsFixed"
        }
      >
        <div
          className="filters"
          onClick={() => setOpenMobileFilter(!openMobileFilter)}
        >
          {" "}
          <h4>Filtry</h4>
        </div>
        <div className="filtersContainer">
          <div className="container">
            <div className="boxFilter">
              <Accordion preExpanded={[1, 2]} allowZeroExpanded>
                <AccordionItem uuid={1}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="header">
                        <h4>Styl rejsu</h4>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="tripProgram">
                      {Types.map((el) => {
                        const imageAlt = el.Image?.alt;
                        const imageUrl = el.Image?.url;
                        return (
                          <label className="containerInput">
                            <input
                              type="checkbox"
                              value={el.OfferTypeId}
                              checked={
                                serializedQuery["type"] &&
                                serializedQuery["type"].includes(el.OfferTypeId)
                              }
                              onChange={handleCheckedTypes}
                            ></input>
                            <span className="checkmark"></span>
                            <div className="oneBox"></div>
                            <p className="titleText">
                              {el.Name}
                              <span>
                                (
                                {
                                  arrForFilters.filter(
                                    (item) =>
                                      item.Type.OfferTypeId === el.OfferTypeId
                                  ).length
                                }
                                )
                              </span>
                            </p>
                            <img src={imageUrl} alt={imageAlt} />
                          </label>
                        );
                      })}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="boxFilter">
              {/* <p className="clear" onClick={this.props.handleClearCountry}>wyczyść</p> */}
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="header">
                        <h4>Destynacje</h4>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="tripCountry">
                      <AccordionItemPanel>
                        {Countries.map(
                          (el) => {
                              const imageAlt = el.Image?.alt;
                              const imageUrl = el.Image?.url;
                              return (
                            el.OffersCount !== "0" && (
                              <label className="containerInput">
                                <input
                                  type="checkbox"
                                  name="Name"
                                  value={el.CountryId}
                                  checked={
                                    serializedQuery["destination"] &&
                                    serializedQuery["destination"].includes(
                                      el.CountryId
                                    )
                                  }
                                  onChange={handleCheckedDestination}
                                ></input>
                                <span className="checkmark"></span>
                                <div className="oneBox"></div>
                                <p className="titleText">
                                  {el.Name}
                                  <span>
                                    (
                                    {
                                      arrForFilters.filter(
                                        (item) =>
                                          item.Region.Country.CountryId ===
                                          el.CountryId
                                      ).length
                                    }
                                    )
                                  </span>
                                </p>
                                <img src={imageUrl} alt={imageAlt} />
                              </label>
                            )
                              )})}
                      </AccordionItemPanel>

                      {/* </AccordionItem>
                                </Accordion > */}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="boxFilter">
              {/* <p className="clear">wyczyść</p> */}
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="header">
                        <h4>Termin</h4>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    <div className="tripTerm">
                      {Terms.map(
                        (el) =>
                          el.OffersCount !== "0" && (
                            <label className="containerInput">
                              <input
                                type="checkbox"
                                value={el.OfferTermId}
                                checked={
                                  serializedQuery["term"] &&
                                  serializedQuery["term"].includes(
                                    el.OfferTermId
                                  )
                                }
                                onChange={handleCheckedTerms}
                              ></input>
                              <span className="checkmark"></span>
                              <div className="oneBox"></div>
                              <p className="titleText">
                                {el.Name}
                                <span>
                                  (
                                  {
                                    arrForFilters.filter(
                                      (item) =>
                                        item.Availability.Terms[0]
                                          .OfferTermId === el.OfferTermId
                                    ).length
                                  }
                                  )
                                </span>
                              </p>
                            </label>
                          )
                      )}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <div className="charterBox">
            <p>
              <span style={{ fontFamily: "SpaceGrotesk-SemiBold" }}>
                Szukasz rejsu w innym terminie?
              </span>
              <br></br>
              Odezwij się do nas!<br></br>
              Organizujemy także rejsy na życzenie.
            </p>
            {/* <Link to='#' onClick={() => {window.location = "mailto:info@theboattrip.eu"}} style={{ fontFamily: "SpaceGrotesk-SemiBold" }}>info@theboattrip.eu</Link> */}

            {/* <a href="mailto:info@theboattrip.eu">info@theboattrip.eu</a> */}
            {/* <p><a href="tel:+48 577 543 094">+48 577 543 094</a></p> */}
            <p style={{ fontFamily: "SpaceGrotesk-SemiBold" }}>
              info@theboattrip.eu<br></br>
              +48 577 543 094
            </p>
          </div>
        </div>
      </div>
      <ListOfTrips List={list} newArr={newArr} />
    </>
  );
};
