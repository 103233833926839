import React, { useState } from 'react';
import './ReservationInfo.scss';
import './offersite/OfferAccomodation.scss';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import './AccommodationLp.scss';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import APIbackend from './config.js';
import jachtPremier1 from './img/jachtPremier1.jpg';
import jachtPremier2 from './img/jachtPremier2.jpg';
import jachtPremier3 from './img/jachtPremier3.jpg';
import jachtPremier4 from './img/jachtPremier4.jpg';
import jachtPremier5 from './img/jachtPremier5.jpg';

import jachtSuperior1 from './img/jachtSuperior1.jpg';
import jachtSuperior2 from './img/jachtSuperior2.jpg';
import jachtSuperior3 from './img/jachtSuperior3.jpg';
import jachtSuperior4 from './img/jachtSuperior4.jpg';
import jachtSuperior5 from './img/jachtSuperior5.jpg';

import jachtLuxury1 from './img/jachtLuxury1.jpg';
import jachtLuxury2 from './img/jachtLuxury2.jpg';
import jachtLuxury3 from './img/jachtLuxury3.jpg';
import jachtLuxury4 from './img/jachtLuxury4.jpg';
import jachtLuxury5 from './img/jachtLuxury5.jpg';

import katLuxury1 from './img/katLuxury1.jpg';
import katLuxury2 from './img/katLuxury2.jpg';
import katLuxury3 from './img/katLuxury3.jpg';
import katLuxury4 from './img/katLuxury4.jpg';
import katLuxury5 from './img/katLuxury5.jpg';

import katSuperior1 from './img/katSuperior1.jpg';
import katSuperior2 from './img/katSuperior2.jpg';
import katSuperior3 from './img/katSuperior3.jpg';
import katSuperior4 from './img/katSuperior4.jpg';
import katSuperior5 from './img/katSuperior5.jpg';

import katPremier1 from './img/katPremier1.jpg';
import katPremier2 from './img/katPremier2.jpg';
import katPremier3 from './img/katPremier3.jpg';
import katPremier4 from './img/katPremier4.jpg';
import katPremier5 from './img/katPremier5.jpg';

const AccommodationLp = () => {
  const [showPopup, togglePopupShow] = useState(false);
  const [showPopup2, togglePopupShow2] = useState(false);
  const [showPopup3, togglePopupShow3] = useState(false);
  const [showPopup4, togglePopupShow4] = useState(false);
  const [showPopup5, togglePopupShow5] = useState(false);

  function toggle() {
    togglePopupShow(!showPopup);
  }

  function toggle2() {
    togglePopupShow2(!showPopup2);
  }

  function toggle3() {
    togglePopupShow3(!showPopup3);
  }

  function toggle4() {
    togglePopupShow4(!showPopup4);
  }

  function toggle5() {
    togglePopupShow5(!showPopup5);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };

  const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
  };

  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
  };

  const togglePopup5 = () => {
    setIsOpen5(!isOpen5);
  };

  const togglePopup6 = () => {
    setIsOpen6(!isOpen6);
  };

  return (
    <>
      <div className="offerAccommodationSection">
        <div className="offerAccommodationTitleWrapper">
          <h2>Jachty i katamarany</h2>
          <p>
            <p>
              Nowoczesne jachty i katamarany to ekwiwalent pięknych
              apartamentów, tylko mobilnych - pływających!&nbsp;Dzięki temu
              codziennie budzisz się w nowym, klimatycznym miejscu, masz
              prywatną przestrzeń do chill-out'u&nbsp;na pokładzie i opcję
              kąpieli na wyciągnięcie ręki.&nbsp;Podróżowanie nigdy nie było tak
              przyjemne!&nbsp;
            </p>
          </p>
        </div>
        <div id="offerResidenceWrapper" className="accLp">
          <Tabs defaultTab="basic-tab-one">
            <TabList>
              <Tab tabFor="basic-tab-one">Klasa Premier</Tab>
              <Tab tabFor="basic-tab-two">Klasa Superior</Tab>
              <Tab tabFor="basic-tab-three">Klasa Luxury</Tab>
            </TabList>

            <TabPanel tabId="basic-tab-one">
              <div className="residencesTab">
                <div className="offerResidenceRoomsWrapper">
                  <div className="offerResidenceRoom">
                    <div className="RoomLeft" onClick={togglePopup}>
                      <div className="image">
                        <img src={jachtPremier1} alt="Jacht Premier" />
                      </div>
                    </div>
                    <div className="RoomRight">
                      <div className="RoomRightTitle">
                        <p>Jacht PREMIER</p>
                      </div>
                      <div className="RoomRightContent">
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                            <div></div>
                            <p>Ilość miejsc</p>
                          </div>
                          <p className="RoomRightItem-Value">10</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                            <div></div>
                            <p>Łazienki</p>
                          </div>
                          <p className="RoomRightItem-Value">2-3</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                            <div></div>
                            <p>Skipper</p>
                          </div>
                          <p className="RoomRightItem-Value">TAK</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                            <div></div>
                            <p>Kajuty</p>
                          </div>
                          <p className="RoomRightItem-Value">4</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                            <div></div>
                            <p>Długość jednostki</p>
                          </div>
                          <p className="RoomRightItem-Value">39-40ft</p>
                        </div>
                      </div>
                    </div>
                    {isOpen && (
                      <div className="popup-box">
                        <div className="box">
                          <span className="close-icon" onClick={togglePopup}>
                            x
                          </span>
                          <div className="popupContent">
                            <p className="popup-title">Jacht PREMIER</p>
                            <p>
                              <p>
                                Wybierając tę kategorię trafisz na duży 10
                                osobowy jacht z 4 kabinami 2-os. i 2-3
                                łazienkami. Masz gwarancję, że jacht będzie nowy
                                (max. 5 lat). To górna półka jachtów z elegancką
                                częścią hotelową (ekwiwalent apartamentu 4*), w
                                pełni wyposażoną kuchnią, salonem (gdzie śpią 2
                                osoby) i komfortowym pokładem z wygodną
                                platformę kąpielową.&nbsp;
                              </p>
                            </p>
                          </div>
                          <div className="popupImgGallery">
                            <div className="ColageImages">
                              <div className="image" onClick={toggle}>
                                <img src={jachtPremier1} alt="Jacht Premier" />
                              </div>

                              {showPopup && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtPremier1}
                                      alt="Jacht Premier"
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="image" onClick={toggle2}>
                                <img
                                  src={jachtPremier2}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>

                              {showPopup2 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle2}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtPremier2}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="image" onClick={toggle3}>
                                <img
                                  src={jachtPremier3}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>

                              {showPopup3 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle3}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtPremier3}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="image" onClick={toggle4}>
                                <img
                                  src={jachtPremier4}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>

                              {showPopup4 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle4}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtPremier4}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="PlanImage Image" onClick={toggle5}>
                              <img
                                src={jachtPremier5}
                                alt="Rejsy morskie jachtem"
                              />
                            </div>

                            {showPopup5 && (
                              <div
                                className="popUpImageYachts"
                                onClick={toggle5}
                                style={{ cursor: 'pointer' }}
                              >
                                <div className="popupInner">
                                  <button
                                    className="closeButton"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    ✖
                                  </button>
                                  <img
                                    src={jachtPremier5}
                                    alt="Rejsy morskie jachtem"
                                    style={{ transform: 'rotate(90deg)' }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {isOpen2 && (
                      <div class="popup-box">
                        <div class="box">
                          <span class="close-icon" onClick={togglePopup2}>
                            x
                          </span>
                          <div class="popupContent">
                            <p class="popup-title">Katamaran PREMIER</p>
                            <p>
                              <p>
                                Wybierając tę kategorię trafisz na 12 osobowy
                                katamaran o długości 39-40 stóp z w pełni
                                wyposażoną kuchnią, 2-4 łazienkami oraz dużym
                                salonem. Masz gwarancję, że katamaran będzie
                                nowy (max. 5 lat). Część hotelowa jest elegancka
                                i bardzo przestronna (standard 4*). Kwaterujemy
                                tu 10 gości + skippera (patrz schemat
                                kabin).&nbsp;
                              </p>
                            </p>
                          </div>
                          <div class="popupImgGallery">
                            <div class="ColageImages">
                              <div class="image" onClick={toggle}>
                                <img
                                  src={katPremier1}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                              {showPopup && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={katPremier1}
                                      alt="Rejsy morskie katamaranem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle2}>
                                <img
                                  src={katPremier2}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                              {showPopup2 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle2}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={katPremier2}
                                      alt="Rejsy morskie katamaranem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle3}>
                                <img
                                  src={katPremier3}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                              {showPopup3 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle3}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={katPremier3}
                                      alt="Rejsy morskie katamaranem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle4}>
                                <img
                                  src={katPremier4}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                              {showPopup4 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle4}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={katPremier4}
                                      alt="Rejsy morskie katamaranem"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>

                            <div class="PlanImage image" onClick={toggle5}>
                              <img
                                src={katPremier5}
                                alt="Rejsy morskie katamaranem"
                              />
                            </div>
                            {showPopup5 && (
                              <div
                                className="popUpImageYachts"
                                onClick={toggle5}
                                style={{ cursor: 'pointer' }}
                              >
                                <div className="popupInner">
                                  <button
                                    className="closeButton"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    ✖
                                  </button>
                                  <img
                                    src={katPremier5}
                                    alt="Rejsy morskie katamaranem"
                                    style={{ transform: 'rotate(90deg)' }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="offerResidenceRoom">
                    <div className="RoomLeft" onClick={togglePopup2}>
                      <div className="image">
                        <img
                          src={katPremier1}
                          alt="Rejsy morskie katamaranem"
                        />
                      </div>
                    </div>
                    <div className="RoomRight">
                      <div className="RoomRightTitle">
                        <p>Katamaran PREMIER</p>
                      </div>
                      <div className="RoomRightContent">
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                            <div></div>
                            <p>Ilość miejsc</p>
                          </div>
                          <p className="RoomRightItem-Value">12</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                            <div></div>
                            <p>Łazienki</p>
                          </div>
                          <p className="RoomRightItem-Value">2-4</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                            <div></div>
                            <p>Skipper</p>
                          </div>
                          <p className="RoomRightItem-Value">TAK</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                            <div></div>
                            <p>Kajuty</p>
                          </div>
                          <p className="RoomRightItem-Value">4</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                            <div></div>
                            <p>Długość jednostki</p>
                          </div>
                          <p className="RoomRightItem-Value">39-40ft</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel tabId="basic-tab-two">
              <div className="residencesTab">
                <div className="offerResidenceRoomsWrapper">
                  <div className="offerResidenceRoom">
                    <div className="RoomLeft" onClick={togglePopup3}>
                      <div className="image">
                        <img src={jachtSuperior1} alt="Rejsy morskie jachtem" />
                      </div>
                    </div>
                    <div className="RoomRight">
                      <div className="RoomRightTitle">
                        <p>Jacht SUPERIOR</p>
                      </div>
                      <div className="RoomRightContent">
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                            <div></div>
                            <p>Ilość miejsc</p>
                          </div>
                          <p className="RoomRightItem-Value">12</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                            <div></div>
                            <p>Łazienki</p>
                          </div>
                          <p className="RoomRightItem-Value">3-4</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                            <div></div>
                            <p>Skipper</p>
                          </div>
                          <p className="RoomRightItem-Value">TAK</p>
                        </div>

                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                            <div></div>
                            <p>Kajuty</p>
                          </div>
                          <p className="RoomRightItem-Value">5</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                            <div></div>
                            <p>Długość jednostki</p>
                          </div>
                          <p className="RoomRightItem-Value">45-58ft</p>
                        </div>
                      </div>
                    </div>
                    {isOpen3 && (
                      <div class="popup-box">
                        <div class="box">
                          <span class="close-icon" onClick={togglePopup3}>
                            x
                          </span>
                          <div class="popupContent">
                            <p class="popup-title">Jacht SUPERIOR</p>
                            <p>
                              <p>
                                W tej klasie znajdziesz bardzo duże 12 osobowe
                                jachty z 5 kabinami 2-os., w pełni wyposażoną
                                kuchnią, bardzo dużym salonem (gdzie śpią 2
                                osoby) i 3-4 łazienkami. Te jachty są
                                analogiczne do klasy Premier pod kątem wieku
                                (max. 5 lat) i wyposażenia, ale są po prostu
                                większe i bardziej przestronne. To ekwiwalent
                                apartamentu 4*+.
                              </p>
                            </p>
                          </div>
                          <div class="popupImgGallery">
                            <div class="ColageImages">
                              <div class="image" onClick={toggle}>
                                <img
                                  src={jachtSuperior1}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>
                              {showPopup && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtSuperior1}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle2}>
                                <img
                                  src={jachtSuperior2}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>
                              {showPopup2 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle2}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtSuperior2}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle3}>
                                <img
                                  src={jachtSuperior3}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>
                              {showPopup3 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle3}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtSuperior3}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle4}>
                                <img
                                  src={jachtSuperior4}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>
                              {showPopup4 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle4}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtSuperior4}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div class="PlanImage image" onClick={toggle5}>
                              <img
                                src={jachtSuperior5}
                                alt="Rejsy morskie jachtem"
                              />
                            </div>
                            {showPopup5 && (
                              <div
                                className="popUpImageYachts"
                                onClick={toggle5}
                                style={{ cursor: 'pointer' }}
                              >
                                <div className="popupInner">
                                  <button
                                    className="closeButton"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    ✖
                                  </button>
                                  <img
                                    src={jachtSuperior5}
                                    alt="Rejsy morskie jachtem"
                                    style={{ transform: 'rotate(90deg)' }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="offerResidenceRoom">
                    <div className="RoomLeft" onClick={togglePopup4}>
                      <div className="image">
                        <img
                          src={katSuperior1}
                          alt="Rejsy morskie katamaranem"
                        />
                      </div>
                    </div>
                    <div className="RoomRight">
                      <div className="RoomRightTitle">
                        <p>Katamaran SUPERIOR</p>
                      </div>
                      <div className="RoomRightContent">
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                            <div></div>
                            <p>Ilość miejsc</p>
                          </div>
                          <p className="RoomRightItem-Value">12</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                            <div></div>
                            <p>Łazienki</p>
                          </div>
                          <p className="RoomRightItem-Value">2-4</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                            <div></div>
                            <p>Skipper</p>
                          </div>
                          <p className="RoomRightItem-Value">TAK</p>
                        </div>

                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                            <div></div>
                            <p>Kajuty</p>
                          </div>
                          <p className="RoomRightItem-Value">4</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                            <div></div>
                            <p>Długość jednostki</p>
                          </div>
                          <p className="RoomRightItem-Value">42-46ft</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isOpen4 && (
                  <div class="popup-box">
                    <div class="box">
                      <span class="close-icon" onClick={togglePopup4}>
                        x
                      </span>
                      <div class="popupContent">
                        <p class="popup-title">Katamaran SUPERIOR</p>
                        <p>
                          <p>
                            Tu znajdziesz bardzo duże, 12 osobowe katamarany o
                            długości 42-46 stóp, które są analogiczne do klasy
                            Premier pod kątem wieku (nowe lub max. 5 lat) i
                            wyposażenia, ale są po prostu dużo większe i
                            przestronniejsze co dodatkowo zwiększa komfort
                            (4*+). Kwaterujemy tu 10 gości + skippera (patrz
                            schemat kabin).&nbsp;
                          </p>
                        </p>
                      </div>
                      <div class="popupImgGallery">
                        <div class="ColageImages">
                          <div class="image" onClick={toggle}>
                            <img
                              src={katSuperior1}
                              alt="Rejsy morskie katamaranem"
                            />
                          </div>
                          {showPopup && (
                            <div
                              className="popUpImageYachts"
                              onClick={toggle}
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="popupInner">
                                <button
                                  className="closeButton"
                                  style={{ cursor: 'pointer' }}
                                >
                                  ✖
                                </button>
                                <img
                                  src={katSuperior1}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                            </div>
                          )}
                          <div class="image" onClick={toggle2}>
                            <img
                              src={katSuperior2}
                              alt="Rejsy morskie katamaranem"
                            />
                          </div>
                          {showPopup2 && (
                            <div
                              className="popUpImageYachts"
                              onClick={toggle2}
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="popupInner">
                                <button
                                  className="closeButton"
                                  style={{ cursor: 'pointer' }}
                                >
                                  ✖
                                </button>
                                <img
                                  src={katSuperior2}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                            </div>
                          )}
                          <div class="image" onClick={toggle3}>
                            <img
                              src={katSuperior3}
                              alt="Rejsy morskie katamaranem"
                            />
                          </div>
                          {showPopup3 && (
                            <div
                              className="popUpImageYachts"
                              onClick={toggle3}
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="popupInner">
                                <button
                                  className="closeButton"
                                  style={{ cursor: 'pointer' }}
                                >
                                  ✖
                                </button>
                                <img
                                  src={katSuperior3}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                            </div>
                          )}
                          <div class="image" onClick={toggle4}>
                            <img
                              src={katSuperior4}
                              alt="Rejsy morskie katamaranem"
                            />
                          </div>
                          {showPopup4 && (
                            <div
                              className="popUpImageYachts"
                              onClick={toggle4}
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="popupInner">
                                <button
                                  className="closeButton"
                                  style={{ cursor: 'pointer' }}
                                >
                                  ✖
                                </button>
                                <img
                                  src={katSuperior4}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div class="PlanImage image" onClick={toggle5}>
                          <img
                            src={katSuperior5}
                            alt="Rejsy morskie katamaranem"
                          />
                        </div>
                        {showPopup5 && (
                          <div
                            className="popUpImageYachts"
                            onClick={toggle5}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="popupInner">
                              <button
                                className="closeButton"
                                style={{ cursor: 'pointer' }}
                              >
                                ✖
                              </button>
                              <img
                                src={katSuperior5}
                                alt="Rejsy morskie katamaranem"
                                style={{ transform: 'rotate(90deg)' }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </TabPanel>

            <TabPanel tabId="basic-tab-three">
              <div className="residencesTab">
                <div className="offerResidenceRoomsWrapper">
                  <div className="offerResidenceRoom">
                    <div className="RoomLeft" onClick={togglePopup5}>
                      <div className="image">
                        <img src={katLuxury1} alt="Rejsy morskie katamaranem" />
                      </div>
                    </div>
                    <div className="RoomRight">
                      <div className="RoomRightTitle">
                        <p>Katamaran LUXURY</p>
                      </div>
                      <div className="RoomRightContent">
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                            <div></div>
                            <p>Ilość miejsc</p>
                          </div>
                          <p className="RoomRightItem-Value">12</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                            <div></div>
                            <p>Łazienki</p>
                          </div>
                          <p className="RoomRightItem-Value">4-6</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                            <div></div>
                            <p>Skipper</p>
                          </div>
                          <p className="RoomRightItem-Value">TAK</p>
                        </div>

                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                            <div></div>
                            <p>Kajuty</p>
                          </div>
                          <p className="RoomRightItem-Value">5-6</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                            <div></div>
                            <p>Długość jednostki</p>
                          </div>
                          <p className="RoomRightItem-Value">48-52ft</p>
                        </div>
                      </div>
                    </div>

                    {isOpen5 && (
                      <div class="popup-box">
                        <div class="box">
                          <span class="close-icon" onClick={togglePopup5}>
                            x
                          </span>
                          <div class="popupContent">
                            <p class="popup-title">Katamaran LUXURY</p>
                            <p>
                              <p>
                                To najbardziej luksusowe i największe katamarany
                                na rynku. Mieszczą 12 osób zakwaterowanych w 6
                                podwójnych kabinach (nikt nie śpi w salonie).
                                Mają 4-6 łazienek, olbrzymi salon z świetnie
                                wyposażoną kuchnią i są naszpikowane użytecznymi
                                gadżetami. Ich standard w żeglarstwie można
                                porównać do hotelu 5*.
                              </p>
                            </p>
                          </div>
                          <div class="popupImgGallery">
                            <div class="ColageImages">
                              <div class="image" onClick={toggle}>
                                <img
                                  src={katLuxury1}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                              {showPopup && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={katLuxury1}
                                      alt="Rejsy morskie katamaranem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle2}>
                                <img
                                  src={katLuxury2}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                              {showPopup2 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle2}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={katLuxury2}
                                      alt="Rejsy morskie katamaranem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle3}>
                                <img
                                  src={katLuxury3}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                              {showPopup3 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle3}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={katLuxury3}
                                      alt="Rejsy morskie katamaranem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle4}>
                                <img
                                  src={katLuxury4}
                                  alt="Rejsy morskie katamaranem"
                                />
                              </div>
                              {showPopup4 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle4}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={katLuxury4}
                                      alt="Rejsy morskie katamaranem"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div class="PlanImage image" onClick={toggle5}>
                              <img
                                src={katLuxury5}
                                alt="Rejsy morskie katamaranem"
                              />
                            </div>
                            {showPopup5 && (
                              <div
                                className="popUpImageYachts"
                                onClick={toggle5}
                                style={{ cursor: 'pointer' }}
                              >
                                <div className="popupInner">
                                  <button
                                    className="closeButton"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    ✖
                                  </button>
                                  <img
                                    src={katLuxury5}
                                    alt="Rejsy morskie katamaranem"
                                    style={{ transform: 'rotate(90deg)' }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="offerResidenceRoom">
                    <div className="RoomLeft" onClick={togglePopup6}>
                      <div className="image">
                        <img src={jachtLuxury1} alt="Rejsy morskie jachtem" />
                      </div>
                    </div>
                    <div className="RoomRight">
                      <div className="RoomRightTitle">
                        <p>Jacht LUXURY</p>
                      </div>
                      <div className="RoomRightContent">
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                            <div></div>
                            <p>Ilość miejsc</p>
                          </div>
                          <p className="RoomRightItem-Value">12</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                            <div></div>
                            <p>Łazienki</p>
                          </div>
                          <p className="RoomRightItem-Value">4-5</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                            <div></div>
                            <p>Skipper</p>
                          </div>
                          <p className="RoomRightItem-Value">TAK</p>
                        </div>

                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                            <div></div>
                            <p>Kajuty</p>
                          </div>
                          <p className="RoomRightItem-Value">5</p>
                        </div>
                        <div className="RoomRightItem">
                          <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                            <div></div>
                            <p>Długość jednostki</p>
                          </div>
                          <p className="RoomRightItem-Value">52-58ft</p>
                        </div>
                      </div>
                    </div>
                    {isOpen6 && (
                      <div class="popup-box">
                        <div class="box">
                          <span class="close-icon" onClick={togglePopup6}>
                            x
                          </span>
                          <div class="popupContent">
                            <p class="popup-title">Jacht LUXURY</p>
                            <p>
                              <p>
                                To najbardziej luksusowe i największe jachty na
                                rynku. Mieszczą 12 osób zakwaterowanych w 6
                                podwójnych kabinach (nikt nie śpi w salonie).
                                Mają 3-4 łazienki, olbrzymi salon z świetnie
                                wyposażoną kuchnią i są naszpikowane użytecznymi
                                gadżetami. Ich standard w żeglarstwie można
                                porównać do hotelu 5*.
                              </p>
                            </p>
                          </div>
                          <div class="popupImgGallery">
                            <div class="ColageImages">
                              <div class="image" onClick={toggle}>
                                <img
                                  src={jachtLuxury1}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>
                              {showPopup && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtLuxury1}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle2}>
                                <img
                                  src={jachtLuxury2}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>
                              {showPopup2 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle2}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtLuxury2}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle3}>
                                <img
                                  src={jachtLuxury3}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>
                              {showPopup3 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle3}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtLuxury3}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                              <div class="image" onClick={toggle4}>
                                <img
                                  src={jachtLuxury4}
                                  alt="Rejsy morskie jachtem"
                                />
                              </div>
                              {showPopup4 && (
                                <div
                                  className="popUpImageYachts"
                                  onClick={toggle4}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="popupInner">
                                    <button
                                      className="closeButton"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      ✖
                                    </button>
                                    <img
                                      src={jachtLuxury4}
                                      alt="Rejsy morskie jachtem"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div class="PlanImage image" onClick={toggle5}>
                              <img
                                src={jachtLuxury5}
                                alt="Rejsy morskie jachtem"
                              />
                            </div>
                            {showPopup5 && (
                              <div
                                className="popUpImageYachts"
                                onClick={toggle5}
                                style={{ cursor: 'pointer' }}
                              >
                                <div className="popupInner">
                                  <button
                                    className="closeButton"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    ✖
                                  </button>
                                  <img
                                    src={jachtLuxury5}
                                    alt="Rejsy morskie jachtem"
                                    style={{ transform: 'rotate(90deg)' }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <div id="responsiveAccommodation" className="responsiveLP">
        <Accordion allowZeroExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="offerResidenceRoomTitle">
                  <h5>Jacht PREMIER</h5>
                </div>{' '}
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <div className="offerResidenceRoom">
                <div className="RoomLeft" onClick={togglePopup}>
                  <div className="image">
                    <img src={jachtPremier1} alt="Jacht Premier" />
                  </div>
                </div>
                <div className="RoomRight">
                  <div className="RoomRightTitle">
                    <p>Jacht PREMIER</p>
                    <span>W cenie wyjazdu</span>
                  </div>
                  <div className="RoomRightContent">
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                        <div></div>
                        <p>Ilość miejsc</p>
                      </div>
                      <p className="RoomRightItem-Value">10</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                        <div></div>
                        <p>Łazienki</p>
                      </div>
                      <p className="RoomRightItem-Value">2-3</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                        <div></div>
                        <p>Skipper</p>
                      </div>
                      <p className="RoomRightItem-Value">TAK</p>
                    </div>

                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                        <div></div>
                        <p>Kajuty</p>
                      </div>
                      <p className="RoomRightItem-Value">4</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                        <div></div>
                        <p>Długość jednostki</p>
                      </div>
                      <p className="RoomRightItem-Value">39-40ft</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="offerResidenceRoomTitle">
                  <h5>Katamaran PREMIER</h5>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <div className="offerResidenceRoom">
                <div className="RoomLeft" onClick={togglePopup}>
                  <div className="image">
                    <img
                      src={`${APIbackend}/static/offer/room/1503528970.jpg`}
                      alt="Rejsy morskie katamaranem"
                    />
                  </div>
                </div>
                <div className="RoomRight">
                  <div className="RoomRightTitle">
                    <p>Katamaran PREMIER</p>
                  </div>
                  <div className="RoomRightContent">
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                        <div></div>
                        <p>Ilość miejsc</p>
                      </div>
                      <p className="RoomRightItem-Value">12</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                        <div></div>
                        <p>Łazienki</p>
                      </div>
                      <p className="RoomRightItem-Value">2-4</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                        <div></div>
                        <p>Skipper</p>
                      </div>
                      <p className="RoomRightItem-Value">TAK</p>
                    </div>

                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                        <div></div>
                        <p>Kajuty</p>
                      </div>
                      <p className="RoomRightItem-Value">4</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                        <div></div>
                        <p>Długość jednostki</p>
                      </div>
                      <p className="RoomRightItem-Value">39-40ft</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="offerResidenceRoomTitle">
                  <h5>Jacht SUPERIOR</h5>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <div className="offerResidenceRoom">
                <div className="RoomLeft" onClick={togglePopup}>
                  <div className="image">
                    <img
                      src={`${APIbackend}/static/offer/room/1262596518.jpg`}
                      alt="Rejsy morskie jachtem"
                    />
                  </div>
                </div>
                <div className="RoomRight">
                  <div className="RoomRightTitle">
                    <p>Jacht SUPERIOR</p>
                  </div>
                  <div className="RoomRightContent">
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                        <div></div>
                        <p>Ilość miejsc</p>
                      </div>
                      <p className="RoomRightItem-Value">12</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                        <div></div>
                        <p>Łazienki</p>
                      </div>
                      <p className="RoomRightItem-Value">3-4</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                        <div></div>
                        <p>Skipper</p>
                      </div>
                      <p className="RoomRightItem-Value">TAK</p>
                    </div>

                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                        <div></div>
                        <p>Kajuty</p>
                      </div>
                      <p className="RoomRightItem-Value">5</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                        <div></div>
                        <p>Długość jednostki</p>
                      </div>
                      <p className="RoomRightItem-Value">45-58ft</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="offerResidenceRoomTitle">
                  <h5>Katamaran SUPERIOR</h5>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <div className="offerResidenceRoom">
                <div className="RoomLeft" onClick={togglePopup}>
                  <div className="image">
                    <img src={jachtSuperior1} alt="Rejsy morskie jachtem" />
                  </div>
                </div>
                <div className="RoomRight">
                  <div className="RoomRightTitle">
                    <p>Katamaran SUPERIOR</p>
                  </div>
                  <div className="RoomRightContent">
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                        <div></div>
                        <p>Ilość miejsc</p>
                      </div>
                      <p className="RoomRightItem-Value">11</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                        <div></div>
                        <p>Łazienki</p>
                      </div>
                      <p className="RoomRightItem-Value">2-4</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                        <div></div>
                        <p>Skipper</p>
                      </div>
                      <p className="RoomRightItem-Value">TAK</p>
                    </div>

                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                        <div></div>
                        <p>Kajuty</p>
                      </div>
                      <p className="RoomRightItem-Value">4</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                        <div></div>
                        <p>Długość jednostki</p>
                      </div>
                      <p className="RoomRightItem-Value">42-46ft</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="offerResidenceRoomTitle">
                  <h5>Katamaran LUXURY</h5>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <div className="offerResidenceRoom">
                <div className="RoomLeft" onClick={togglePopup}>
                  <div className="image">
                    <img src={katLuxury1} alt="Rejsy morskie katamaranem" />
                  </div>
                </div>
                <div className="RoomRight">
                  <div className="RoomRightTitle">
                    <p>Katamaran LUXURY</p>
                  </div>
                  <div className="RoomRightContent">
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                        <div></div>
                        <p>Ilość miejsc</p>
                      </div>
                      <p className="RoomRightItem-Value">12</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                        <div></div>
                        <p>Łazienki</p>
                      </div>
                      <p className="RoomRightItem-Value">4-6</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                        <div></div>
                        <p>Skipper</p>
                      </div>
                      <p className="RoomRightItem-Value">TAK</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                        <div></div>
                        <p>Kajuty</p>
                      </div>
                      <p className="RoomRightItem-Value">5-6</p>
                    </div>
                    <div className="RoomRightItem">
                      <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                        <div></div>
                        <p>Długość jednostki</p>
                      </div>
                      <p className="RoomRightItem-Value">48-52ft</p>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};

export default AccommodationLp;
