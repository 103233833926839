import React from "react";
import "./PanelMenu.scss";
import {Tab, Tabs, TabPanel, TabList} from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import YourTrips from "./YourTrips.js";
import ChangePassword from "./ChangePassword.js";
import YourDetails from "./YourDetails.js";
import Messages from "./Messages.js";
import Ambassador from "./Ambassador.js";
import Vouchers from "./Vouchers.js";
import LogOut from "./LogOut.js";
import APIbackend from "../config.js";
import {Helmet} from "react-helmet";
import {Redirect} from "react-router-dom";
import {BrowserRouter as Router, Switch} from "react-router-dom";

const trueMessJoinToGroupCode200 = "✔ Witaj w ekipie!";
const trueMessCreateGroupCode200 = "✔ Utworzono nowy kod. ";
const errorMessCreateCroupCode409 = "Kod grupowy jest już przypisany do rezerwacji";
const errorMessJoinToGroupCode409 = "Kod grupowy jest powiązany z inną ofertą";
const errorMessJoinToGroupCode404 = "Kod grupowy nie istnieje";
const errorMessJoinToGroupCode400 = "Kod jest nieprawdiłowy";
const errorMessCreateGroupCode400 = "Nie można utworzyć kodu grupowego";

class PanelMenu extends React.Component {
  state = {
    siteId: 1,
    logoutActive: false,
    messagesActive: false,
    yourtripsActive: true,
    vouchersActive: false,
    ambasadorsActive: false,
    passwordActive: false,
    yourdetailsActive: false,
    isLoading: true,
    errorMessageParametersGetAddons: "",
    errorMessageParametersGetAccommodation: "",
    errorMessageParametersGetInsurance: "",
    errorMessageParametersGetParticipants: "",
    errorMessageParametersGetTransport: "",
    messageNotReadyInvoiceDownload: "",
    messageNotReadyContractDownload: "",
    donwloadUrl: "",
    filedReady: "",
    Reservations: [],
    userPanelActive: true,
    loginActive: false,
    hasNewMessages: "",
    showPopup: false,
    tourDetails: false,
    reservationId: "",
    panelLog: true,
    isAmbassador: true,
    messagesGet: false,
    MessagesArr: [],
    voucherCode: "",
    colorMessage: "",
    accommodationConfig: [],
    journeyConfig: [],
    insuranceConfig: [],
    addonsConfig: [],
    trainingsConfig: [],
    parameters: {},
    userNum: "",
    firstMessage: "",
    secondMessage: "",
    transportButton: true,
    transportMessage: false,
    accommodationButton: true,
    accommodationForm: false,
    insuranceButton: true,
    insuranceMessage: false,
    addonsButton: true,
    addonsMessage: false,
    downloadDocument: false,
    accommodationMessage: false,
    transportDetails: false,
    accommodationDetails: false,
    insuranceDetails: false,
    addonsDetails: false,
    paymentUrl: "",
    paymentUrlFull: "",
    ReservationHistory: [],
    lastTourDetails: "",
    groupCode: "",
    trueMessage: "",
    errorMessage: "",
    render: "",
    isGroupCodeJoinSection: true,
    isGroupCodeCreateSection: true,
  };

  handleAddGroupCode = () => {
    const {siteId, reservationId, groupCode} = this.state;
    fetch(`${APIbackend}/v1/rebates/group/join`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        siteId: siteId,
        reservationId: reservationId,
        groupCode: groupCode,
      }),
    }).then((response) => {
      response.json().then(() => {
        switch (response.status) {
          case 200:
            fetch(`${APIbackend}/v1/account/reservations`, {
              method: "POST",
              body: JSON.stringify({
                siteId: siteId,
              }),
              credentials: "include",
            }).then((response) => {
              response.json().then((data) => {
                this.setState(
                  {
                    Reservations: data.Current,
                  },
                  () =>
                    setTimeout(() => {
                      this.setState({
                        trueMessage: trueMessJoinToGroupCode200,
                        errorMessage: "",
                        groupCode: "",
                        isGroupCodeCreateSection: false,
                      });
                    }, 500)
                );
              });
            });
            break;
          case 409:
            this.setState({
              errorMessage: errorMessJoinToGroupCode409,
              trueMessage: "",
            });
            break;
          case 404:
            this.setState({
              errorMessage: errorMessJoinToGroupCode404,
              trueMessage: "",
            });
            break;
          case 400:
            this.setState({
              errorMessage: errorMessJoinToGroupCode400,
              trueMessage: "",
              groupCode: "",
            });
            break;
          default:
            return null;
        }
      });
    });
  };

  handleCreateGroupCode = (event) => {
    const {siteId, reservationId} = this.state;
    event.preventDefault();

    fetch(`${APIbackend}/v1/rebates/group/create`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        siteId: siteId,
        reservationId: reservationId,
      }),
    }).then((response) => {
      response.json().then((responseData) => {
        switch (response.status) {
          case 200:
            fetch(`${APIbackend}/v1/account/reservations`, {
              method: "POST",
              body: JSON.stringify({
                siteId: siteId,
              }),
              credentials: "include",
            }).then((response) => {
              response.json().then((data) => {
                this.setState({Reservations: data.Current}, () =>
                  setTimeout(() => {
                    this.setState({
                      trueMessage: `${trueMessCreateGroupCode200} Kod grupowy to: ${parseInt(responseData.code)}`,
                      errorMessage: "",
                      isGroupCodeJoinSection: false,
                    });
                  }, 500)
                );
              });
            });
            break;
          case 409:
            this.setState({
              errorMessage: errorMessCreateCroupCode409,
              trueMessage: "",
            });
            break;
          case 400:
            this.setState({
              errorMessage: errorMessCreateGroupCode400,
              trueMessage: "",
            });
            break;
          default:
            return null;
        }
      });
    });
  };

  handlePay = (event) => {
    event.preventDefault();
    const reservationArr = this.state?.Reservations.find(
      ({ReservationId}) => ReservationId === this.state?.reservationId
    );

    this.setState(
      {
        paymentUrl: (reservationArr?.PaymentUrl).slice(1, (reservationArr?.PaymentUrl).length),
      },
      () => window.location.replace(`${APIbackend}/${this.state.paymentUrl}`)
    );
  };

  handlePayWhole = (event) => {
    event.preventDefault();
    const reservationArr = this.state?.Reservations.find(
      ({ReservationId}) => ReservationId === this.state?.reservationId
    );

    this.setState(
      {
        paymentUrlFull: (reservationArr?.PaymentFullUrl).slice(1, (reservationArr?.PaymentFullUrl).length),
      },
      () => window.location.replace(`${APIbackend}/${this.state.paymentUrlFull}`)
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDownloadInvoice = () => {
    fetch(`${APIbackend}/v1/documents/invoice`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState(
          {
            filedReady: data.FileReady,
            donwloadUrl: data.DownloadURL.slice(1, data.DownloadURL.length),
          },
          () =>
            setTimeout(() => {
              if (this.state.filedReady) {
                window.location.replace(`${APIbackend}/${this.state.donwloadUrl}`);
                this.setState({
                  messageNotReadyInvoiceDownload: "✔ Za chwilę rozpocznie się pobieranie",
                });
              } else {
                this.setState({
                  messageNotReadyInvoiceDownload:
                    "Faktura nie jest możliwa do pobrania. Aby pobrać fakturę należy opłacić wyjazd w całości.",
                });
              }
            }, 500)
        );
      });
    });
  };

  handleDownloadContract = () => {
    fetch(`${APIbackend}/v1/documents/contract`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState(
          {
            filedReady: data.FileReady,
            donwloadUrl: data.DownloadURL.slice(1, data.DownloadURL.length),
          },
          () =>
            setTimeout(() => {
              if (this.state.filedReady) {
                window.location.replace(`${APIbackend}/${this.state.donwloadUrl}`);
                this.setState({
                  messageNotReadyContractDownload: "✔ Za chwilę rozpocznie się pobieranie",
                });
              } else {
                this.setState({
                  messageNotReadyContractDownload: "Nie można pobrać umowy",
                });
              }
            }, 500)
        );
      });
    });
  };

  handleChangeTransport = (participantNumber, event) => {
    this.setState({firstMessage: ""});
    const {journeyConfig} = this.state;
    const elIndex = journeyConfig.findIndex(({userNum}) => userNum === participantNumber);
    journeyConfig[elIndex].journeyId = event.target.value;
    journeyConfig[elIndex].journeyPointId = "";
    this.setState({journeyConfig});
  };

  handleChangeTransportTown = (participantNumber, event) => {
    const {journeyConfig} = this.state;
    const elIndex = journeyConfig.findIndex(({userNum}) => userNum === participantNumber);
    journeyConfig[elIndex].journeyPointId = event.target.value;
    this.setState({journeyConfig});
  };

  handleChangeAccommodation = (participantNumber, event) => {
    const {accommodationConfig} = this.state;
    const elIndex = accommodationConfig.findIndex(({userNum}) => userNum === participantNumber);
    accommodationConfig[elIndex].roomId = event.target.value;
    this.setState({accommodationConfig});
  };

  handleChangeInsurance = (participantNumber, event) => {
    const {insuranceConfig} = this.state;
    const elIndex = insuranceConfig.findIndex(({userNum}) => userNum === participantNumber);
    insuranceConfig[elIndex].insuranceId = event.target.value;
    this.setState({insuranceConfig});
  };

  handleChangeQuarantineAssistance = (participantNumber, event) => {
    const {insuranceConfig} = this.state;
    const elIndex = insuranceConfig.findIndex(({userNum}) => userNum === participantNumber);
    insuranceConfig[elIndex].quarantineAssistanceAccepted = event.target.value;
    this.setState({insuranceConfig});
  };

  handleChangeAddons = (participantNumber, event) => {
    const {addonsConfig} = this.state;
    const elIndex2 = addonsConfig.map(({addonsSelected}) => addonsSelected);
    const elIndex = addonsConfig.findIndex(({userNum}) => userNum === participantNumber);
    elIndex2[elIndex][0].addonId = event.target.value;
    elIndex2[elIndex][0].addonOptionId = "0";
    this.setState({addonsConfig});
  };

  handleDeleteAddon = (participantNumber) => {
    const {addonsConfig} = this.state;
    const elIndex2 = addonsConfig.map(({addonsSelected}) => addonsSelected);
    const elIndex = addonsConfig.findIndex(({userNum}) => userNum === participantNumber);
    elIndex2[elIndex].length = 0;
    this.setState({addonsConfig}, () =>
      setTimeout(() => {
        this.setState({
          userNum: participantNumber,
        });
        var data = {
          siteId: this.state.siteId,
          reservationId: this.state.reservationId,
          data: {
            journeyConfig: this.state.journeyConfig.map(({userNum, journeyId, journeyPointId}) => ({
              userNum: userNum,
              journeyId: journeyId,
              journeyPointId: journeyPointId,
            })),
            accommodationConfig: this.state.accommodationConfig.map(({userNum, roomId, optionsChosen}) => ({
              userNum: userNum,
              roomId: roomId,
              optionsChosen: optionsChosen.map(({bedroomChosen}) => ({
                bedroomChosen: bedroomChosen,
              })),
            })),
            insuranceConfig: this.state.insuranceConfig.map(({userNum, insuranceId, quarantineAssistanceAccepted}) => ({
              userNum: userNum,
              insuranceId: insuranceId,
              quarantineAssistanceAccepted: quarantineAssistanceAccepted
            })),
            trainingsConfig: this.state.trainingsConfig.map(({userNum}) => ({
              userNum: userNum,
              trainingsSelected: [],
            })),
            addonsConfig: this.state.addonsConfig.map(({userNum, addonsSelected}) => ({
              userNum: userNum,
              addonsSelected: addonsSelected.map(({addonId, addonOptionId}) => ({
                addonId: addonId,
                addonOptionId: addonOptionId,
              })),
            })),
          },
        };

        fetch(`${APIbackend}/v1/reservationsUpdates/parameters/change`, {
          method: "POST",
          body: JSON.stringify(data),
          credentials: "include",
        }).then((response) => {
          response.json().then((responseData) => {
            if (response.status !== 200) {
              this.setState(
                {
                  firstMessage: "Dane uczestnika nie zostały zapisane",
                  colorMessage: "red",
                  addonsMessage: true,
                },
                () => window.scrollTo(0, 0)
              );
            }
            if (response.status === 200) {
              this.setState(
                {
                  addonsConfig: this.state.addonsConfig,
                  addonsDetails: false,
                },
                () => window.scrollTo(0, 0)
              );

              if (responseData.ChangesFound && responseData.ChangesSaved && responseData.ChangesValidated) {
                fetch(`${APIbackend}/v1/account/reservations`, {
                  method: "POST",
                  body: JSON.stringify({
                    siteId: this.state.siteId,
                  }),
                  credentials: "include",
                }).then((response) => {
                  response.json().then((data) => {
                    this.setState(
                      {
                        Reservations: data.Current,
                      },
                      () =>
                        setTimeout(() => {
                          const reservationArr2 = data.Current.find(
                            ({ReservationId}) => ReservationId === this.state.reservationId
                          );
                          const lengthOptionsSelected = reservationArr2.OptionsSelected[this.state.userNum].length;
                          this.setState({
                            firstMessage: "Dane zostały poprawnie zapisane",
                            colorMessage: "green",
                            addonsMessage: true,
                            addonsDetails: false,
                            addonsConfig: this.state.addonsConfig,
                            secondMessage:
                              reservationArr2.OptionsSelected[this.state.userNum][lengthOptionsSelected - 1]
                                .Description,
                          });
                        }, 500)
                    );
                  });
                });
              } else if (!responseData.ChangesFound || !responseData.ChangesSaved || !responseData.ChangesValidated) {
                this.setState({
                  firstMessage: "Dane uczestnika nie zostały zapisane.",
                  colorMessage: "red",
                  addonsDetails: false,
                  addonsMessage: true,
                });
              }
            }
          });
        });
      }, 1000)
    );
  };

  handleChangeAddonsOption = (participantNumber, event) => {
    const {addonsConfig} = this.state;
    const elIndex2 = addonsConfig.map(({addonsSelected}) => addonsSelected);
    const elIndex = addonsConfig.findIndex(({userNum}) => userNum === participantNumber);
    elIndex2[elIndex][0].addonOptionId = event.target.value;
    this.setState({addonsConfig});
  };

  handleAllowedTransport = (id) => {
    this.setState({
      firstMessage: "",
      secondMessage: "",
    });

    fetch(`${APIbackend}/v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((dataR) => {
        this.setState({
          Reservations: dataR.Current,
        });

        if (response.status === 200) {
          var data = {
            siteId: this.state.siteId,
            reservationId: this.state.reservationId,
          };

          fetch(`${APIbackend}/v1/reservationsUpdates/allowed`, {
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
          }).then((response) => {
            response.json().then(() => {
              if (response.status === 200) {
                const reservationArr = this.state.Reservations.find(
                  ({ReservationId}) => ReservationId === this.state.reservationId
                );
                this.setState({
                  userNum: id,
                  journeyConfig: reservationArr.StepConfig.Step2.journeyConfig,
                  accommodationConfig: reservationArr.StepConfig.Step2.accommodationConfig,
                  insuranceConfig: reservationArr.StepConfig.Step3.insuranceConfig,
                  addonsConfig: reservationArr.StepConfig.Step4.addonsConfig,
                  trainingsConfig: reservationArr.StepConfig.Step4.trainingsConfig,
                });

                fetch(`${APIbackend}/v1/reservationsUpdates/parameters/get`, {
                  method: "POST",
                  body: JSON.stringify(data),
                  credentials: "include",
                }).then((response) => {
                  response.json().then((data) => {
                    if (response.status !== 200) {
                      this.setState({
                        errorMessageParametersGetTransport:
                          "Minął termin na edycję danych. Prosimy o kontakt z biurem.",
                      });
                    }
                    if (response.status === 200) {
                      this.setState({
                        errorMessageParametersGetTransport: "",
                        parameters: data.Parameters,
                        transportDetails: true,
                        transportButton: false,
                        accommodationDetails: false,
                        journeyConfig: reservationArr.StepConfig.Step2.journeyConfig,
                      });
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
  };

  handleAllowedAccommodation = (id) => {
    this.setState({
      firstMessage: "",
      secondMessage: "",
    });

    fetch(`${APIbackend}/v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((dataR) => {
        this.setState({
          Reservations: dataR.Current,
        });
        if (response.status === 200) {
          var data = {
            siteId: this.state.siteId,
            reservationId: this.state.reservationId,
          };
          fetch(`${APIbackend}/v1/reservationsUpdates/allowed`, {
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
          }).then((response) => {
            response.json().then(() => {
              if (response.status === 200) {
                const reservationArr = this.state.Reservations.find(
                  ({ReservationId}) => ReservationId === this.state.reservationId
                );
                this.setState({
                  userNum: id,
                  journeyConfig: reservationArr.StepConfig.Step2.journeyConfig,
                  accommodationConfig: reservationArr.StepConfig.Step2.accommodationConfig,
                  insuranceConfig: reservationArr.StepConfig.Step3.insuranceConfig,
                  addonsConfig: reservationArr.StepConfig.Step4.addonsConfig,
                  trainingsConfig: reservationArr.StepConfig.Step4.trainingsConfig,
                });
                fetch(`${APIbackend}/v1/reservationsUpdates/parameters/get`, {
                  method: "POST",
                  body: JSON.stringify(data),
                  credentials: "include",
                }).then((response) => {
                  response.json().then((data) => {
                    if (response.status !== 200) {
                      this.setState({
                        errorMessageParametersGetAccommodation:
                          "Minął termin na edycję danych. Prosimy o kontakt z biurem.",
                      });
                    } else if (response.status === 200) {
                      this.setState({
                        errorMessageParametersGetAccommodation: "",
                        parameters: data.Parameters,
                        transportDetails: false,
                        accommodationButton: false,
                        accommodationDetails: true,
                        accommodationConfig: reservationArr.StepConfig.Step2.accommodationConfig,
                      });
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
  };

  handleAllowedInsurance = (id) => {
    this.setState({
      firstMessage: "",
      secondMessage: "",
    });

    fetch(`${APIbackend}/v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((dataR) => {
        this.setState({
          Reservations: dataR.Current,
        });

        if (response.status === 200) {
          var data = {
            siteId: this.state.siteId,
            reservationId: this.state.reservationId,
          };

          fetch(`${APIbackend}/v1/reservationsUpdates/allowed`, {
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
          }).then((response) => {
            response.json().then(() => {
              if (response.status === 200) {
                const reservationArr = this.state.Reservations.find(
                  ({ReservationId}) => ReservationId === this.state.reservationId
                );
                this.setState({
                  userNum: id,
                  journeyConfig: reservationArr.StepConfig.Step2.journeyConfig,
                  accommodationConfig: reservationArr.StepConfig.Step2.accommodationConfig,
                  insuranceConfig: reservationArr.StepConfig.Step3.insuranceConfig,
                  addonsConfig: reservationArr.StepConfig.Step4.addonsConfig,
                  trainingsConfig: reservationArr.StepConfig.Step4.trainingsConfig,
                });

                fetch(`${APIbackend}/v1/reservationsUpdates/parameters/get`, {
                  method: "POST",
                  body: JSON.stringify(data),
                  credentials: "include",
                }).then((response) => {
                  response.json().then((data) => {
                    if (response.status !== 200) {
                      this.setState({
                        errorMessageParametersGetInsurance:
                          "Minął termin na edycję danych. Prosimy o kontakt z biurem.",
                      });
                    }
                    if (response.status === 200) {
                      this.setState({
                        errorMessageParametersGetInsurance: "",
                        parameters: data.Parameters,
                        transportDetails: false,
                        accommodationDetails: false,
                        insuranceButton: false,
                        insuranceDetails: true,
                        insuranceConfig: reservationArr.StepConfig.Step3.insuranceConfig,
                      });
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
  };

  handleAllowedAddons = (id) => {
    this.setState({
      firstMessage: "",
      secondMessage: "",
    });

    fetch(`${APIbackend}/v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((dataR) => {
        this.setState({
          Reservations: dataR.Current,
        });

        if (response.status === 200) {
          var data = {
            siteId: this.state.siteId,
            reservationId: this.state.reservationId,
          };

          fetch(`${APIbackend}/v1/reservationsUpdates/allowed`, {
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
          }).then((response) => {
            response.json().then(() => {
              const reservationArr = this.state.Reservations.find(
                ({ReservationId}) => ReservationId === this.state.reservationId
              );
              if (response.status !== 200) {
                this.setState({});
              }

              if (response.status === 200) {
                this.setState({
                  addonsConfig: reservationArr.StepConfig.Step4.addonsConfig,
                });

                if (reservationArr.StepConfig.Step4.addonsConfig[id - 1].addonsSelected.length === 0) {
                  return (
                    this.state.addonsConfig[id - 1].addonsSelected.push({
                      addonId: "",
                      addonOptionId: "",
                    }),
                    this.setState({
                      userNum: id,
                      journeyConfig: reservationArr.StepConfig.Step2.journeyConfig,
                      accommodationConfig: reservationArr.StepConfig.Step2.accommodationConfig,
                      insuranceConfig: reservationArr.StepConfig.Step3.insuranceConfig,
                      addonsConfig: this.state.addonsConfig,
                      trainingsConfig: reservationArr.StepConfig.Step4.trainingsConfig,
                    }),
                    fetch(`${APIbackend}/v1/reservationsUpdates/parameters/get`, {
                      method: "POST",
                      body: JSON.stringify(data),
                      credentials: "include",
                    }).then((response) => {
                      response.json().then((data) => {
                        if (response.status !== 200) {
                          this.setState({
                            errorMessageParametersGetAddons:
                              "Minął termin na edycję danych. Prosimy o kontakt z biurem.",
                          });
                        }
                        if (response.status === 200) {
                          this.setState({
                            errorMessageParametersGetAddons: "",
                            parameters: data.Parameters,
                            transportDetails: false,
                            accommodationDetails: false,
                            insuranceDetails: false,
                            addonsButton: false,
                            addonsDetails: true,
                            addonsConfig: this.state.addonsConfig,
                          });
                        }
                      });
                    })
                  );
                } else {
                  return this.setState(
                    {
                      addonsConfig: reservationArr.StepConfig.Step4.addonsConfig,
                    },
                    () =>
                      setTimeout(() => {
                        this.setState({
                          userNum: id,
                          journeyConfig: reservationArr.StepConfig.Step2.journeyConfig,
                          accommodationConfig: reservationArr.StepConfig.Step2.accommodationConfig,
                          insuranceConfig: reservationArr.StepConfig.Step3.insuranceConfig,
                          addonsConfig: reservationArr.StepConfig.Step4.addonsConfig,
                          trainingsConfig: reservationArr.StepConfig.Step4.trainingsConfig,
                        });

                        fetch(`${APIbackend}/v1/reservationsUpdates/parameters/get`, {
                          method: "POST",
                          body: JSON.stringify(data),
                          credentials: "include",
                        }).then((response) => {
                          response.json().then((data) => {
                            if (response.status !== 200) {
                              this.setState({
                                errorMessageParametersGetAccommodation: "Rezerwacji nie można już zmienić",
                              });
                            }
                            if (response.status === 200) {
                              this.setState({
                                parameters: data.Parameters,
                                transportDetails: false,
                                accommodationDetails: false,
                                insuranceDetails: false,
                                addonsButton: false,
                                addonsDetails: true,
                                addonsConfig: reservationArr.StepConfig.Step4.addonsConfig,
                              });
                            }
                          });
                        });
                      }, 200)
                  );
                }
              }
            });
          });
        }
      });
    });
  };

  handleCancelTransport = () => {
    fetch(`${APIbackend}/v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState(
          {
            Reservations: data.Current,
          },
          () =>
            setTimeout(() => {
              const reservationArr = this.state.Reservations.find(
                ({ReservationId}) => ReservationId === this.state.reservationId
              );
              this.setState({
                journeyConfig: reservationArr.StepConfig.Step2.journeyConfig,
                firstMessage: "",
                transportDetails: false,
                transportButton: true,
              });
            }, 100)
        );
      });
    });
  };

  handleCancelAccommodation = () => {
    fetch(`${APIbackend}/v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState(
          {
            Reservations: data.Current,
          },
          () =>
            setTimeout(() => {
              const reservationArr = this.state.Reservations.find(
                ({ReservationId}) => ReservationId === this.state.reservationId
              );
              this.setState({
                accommodationConfig: reservationArr.StepConfig.Step2.accommodationConfig,
                firstMessage: "",
                accommodationDetails: false,
                accommodationButton: true,
              });
            }, 100)
        );
      });
    });
  };

  handleCancelInsurance = () => {
    const reservationArr = this.state.Reservations.find((el) => el.ReservationId === this.state.reservationId);
    fetch(`${APIbackend}/v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState({
          Reservations: data.Current,
        });
      });
    });

    this.setState({
      insuranceConfig: reservationArr.StepConfig.Step2.insuranceConfig,
      firstMessage: "",
      insuranceDetails: false,
    });
  };

  handleCancelAddons = () => {
    const reservationArr = this.state.Reservations.find((el) => el.ReservationId === this.state.reservationId);
    fetch(`${APIbackend}/v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState({
          Reservations: data.Current,
        });
      });
    });

    this.setState({
      addonsConfig: reservationArr.StepConfig.Step2.addonsConfig,
      firstMessage: "",
      addonsDetails: false,
    });
  };

  handleSubmitParametersTransport = (participantNumber, event) => {
    event.preventDefault();
    this.setState({
      userNum: participantNumber,
    });

    var data = {
      siteId: this.state.siteId,
      reservationId: this.state.reservationId,
      data: {
        journeyConfig: this.state.journeyConfig.map(({userNum, journeyId, journeyPointId}) => ({
          userNum: userNum,
          journeyId: journeyId,
          journeyPointId: journeyPointId,
        })),
        accommodationConfig: this.state.accommodationConfig.map(({userNum, roomId, optionsChosen}) => ({
          userNum: userNum,
          roomId: roomId,
          optionsChosen: optionsChosen.map(({bedroomChosen}) => ({
            bedroomChosen: bedroomChosen,
          })),
        })),
        insuranceConfig: this.state.insuranceConfig.map(({userNum, insuranceId, quarantineAssistanceAccepted}) => ({
          userNum: userNum,
          insuranceId: insuranceId,
          quarantineAssistanceAccepted: quarantineAssistanceAccepted
        })),
        trainingsConfig: this.state.trainingsConfig.map(({userNum}) => ({
          userNum: userNum,
          trainingsSelected: [],
        })),
        addonsConfig: this.state.addonsConfig.map(({userNum, addonsSelected}) => ({
          userNum: userNum,
          addonsSelected: addonsSelected.map(({addonId, addonOptionId}) => ({
            addonId: addonId,
            addonOptionId: addonOptionId,
          })),
        })),
      },
    };

    fetch(`${APIbackend}/v1/reservationsUpdates/parameters/change`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.status !== 200) {
          this.setState(
            {
              firstMessage: "Dane uczestnika nie zostały zapisane",
              colorMessage: "red",
              transportMessage: true,
            },
            () => window.scrollTo(0, 0)
          );
        }
        if (response.status === 200) {
          this.setState(
            {
              journeyConfig: this.state.journeyConfig,
              transportDetails: false,
            },
            () => window.scrollTo(0, 0)
          );

          if (responseData.ChangesFound && responseData.ChangesSaved && responseData.ChangesValidated) {
            fetch(`${APIbackend}/v1/account/reservations`, {
              method: "POST",
              body: JSON.stringify({
                siteId: this.state.siteId,
              }),
              credentials: "include",
            }).then((response) => {
              response.json().then((data) => {
                this.setState(
                  {
                    Reservations: data.Current,
                  },
                  () =>
                    setTimeout(() => {
                      const reservationArr2 = data.Current.find(
                        ({ReservationId}) => ReservationId === this.state.reservationId
                      );
                      const lengthOptionsSelected = reservationArr2.OptionsSelected[this.state.userNum].length;
                      this.setState({
                        firstMessage: "Dane zostały poprawnie zapisane",
                        colorMessage: "green",
                        transportMessage: true,
                        transportDetails: false,
                        journeyConfig: this.state.journeyConfig,
                        secondMessage:
                          reservationArr2.OptionsSelected[this.state.userNum][lengthOptionsSelected - 1].Description,
                      });
                    }, 500)
                );
              });
            });
          } else if (!responseData.ChangesFound || !responseData.ChangesSaved || !responseData.ChangesValidated) {
            this.setState({
              firstMessage: "Dane uczestnika nie zostały zapisane.",
              colorMessage: "red",
              transportDetails: false,
              transportMessage: true,
            });
          }
        }
      });
    });
  };

  handleSubmitParametersAccommodation = (participantNumber, event) => {
    event.preventDefault();
    this.setState({
      userNum: participantNumber,
    });

    var data = {
      siteId: this.state.siteId,
      reservationId: this.state.reservationId,
      data: {
        journeyConfig: this.state.journeyConfig.map(({userNum, journeyId, journeyPointId}) => ({
          userNum: userNum,
          journeyId: journeyId,
          journeyPointId: journeyPointId,
        })),
        accommodationConfig: this.state.accommodationConfig.map(({userNum, roomId, optionsChosen, bedroomChosen}) => ({
          userNum: userNum,
          roomId: roomId,
          optionsChosen: optionsChosen.map(({bedroomChosen}) => ({
            bedroomChosen: bedroomChosen,
          })),
        })),
        insuranceConfig: this.state.insuranceConfig.map(({userNum, insuranceId, quarantineAssistanceAccepted}) => ({
          userNum: userNum,
          insuranceId: insuranceId,
          quarantineAssistanceAccepted: quarantineAssistanceAccepted
        })),
        trainingsConfig: this.state.trainingsConfig.map(({userNum}) => ({
          userNum: userNum,
          trainingsSelected: [],
        })),
        addonsConfig: this.state.addonsConfig.map(({userNum, addonsSelected}) => ({
          userNum: userNum,
          addonsSelected: addonsSelected.map(({addonId, addonOptionId}) => ({
            addonId: addonId,
            addonOptionId: addonOptionId,
          })),
        })),
      },
    };

    fetch(`${APIbackend}/v1/reservationsUpdates/parameters/change`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.status !== 200) {
          this.setState(
            {
              firstMessage: "Dane uczestnika nie zostały zapisane",
              colorMessage: "red",
              accommodationMessage: true,
            },
            () => window.scrollTo(0, 0)
          );
        }
        if (response.status === 200) {
          this.setState(
            {
              accommodationConfig: this.state.accommodationConfig,
              accommodationDetails: false,
            },
            () => window.scrollTo(0, 0)
          );

          if (responseData.ChangesFound && responseData.ChangesSaved && responseData.ChangesValidated) {
            fetch(`${APIbackend}/v1/account/reservations`, {
              method: "POST",
              body: JSON.stringify({
                siteId: this.state.siteId,
              }),
              credentials: "include",
            }).then((response) => {
              response.json().then((data) => {
                this.setState(
                  {
                    Reservations: data.Current,
                  },
                  () =>
                    setTimeout(() => {
                      const reservationArr2 = data.Current.find(
                        ({ReservationId}) => ReservationId === this.state.reservationId
                      );
                      const lengthOptionsSelected = reservationArr2.OptionsSelected[this.state.userNum].length;
                      this.setState({
                        firstMessage: "Dane zostały poprawnie zapisane",
                        colorMessage: "green",
                        accommodationMessage: true,
                        accommodationDetails: false,
                        accommodationConfig: this.state.accommodationConfig,
                        secondMessage:
                          reservationArr2.OptionsSelected[this.state.userNum][lengthOptionsSelected - 1].Description,
                      });
                    }, 500)
                );
              });
            });
          } else if (!responseData.ChangesFound || !responseData.ChangesSaved || !responseData.ChangesValidated) {
            this.setState({
              firstMessage: "Dane uczestnika nie zostały zapisane.",
              colorMessage: "red",
              accommodationDetails: false,
              accommodationMessage: true,
            });
          }
        }
      });
    });
  };

  handleSubmitParametersInsurance = (participantNumber, event) => {
    event.preventDefault();
    this.setState({
      userNum: participantNumber,
    });

    var data = {
      siteId: this.state.siteId,
      reservationId: this.state.reservationId,
      data: {
        journeyConfig: this.state.journeyConfig.map((el) => ({
          userNum: el.userNum,
          journeyId: el.journeyId,
          journeyPointId: el.journeyPointId,
        })),
        accommodationConfig: this.state.accommodationConfig.map((el) => ({
          userNum: el.userNum,
          roomId: el.roomId,
          optionsChosen: el.optionsChosen.map((el) => ({
            bedroomChosen: el.bedroomChosen,
          })),
        })),
        insuranceConfig: this.state.insuranceConfig.map((el) => ({
          userNum: el.userNum,
          insuranceId: el.insuranceId,
          quarantineAssistanceAccepted: el.quarantineAssistanceAccepted
        })),
        trainingsConfig: this.state.trainingsConfig.map((el) => ({
          userNum: el.userNum,
          trainingsSelected: [],
        })),
        addonsConfig: this.state.addonsConfig.map((el) => ({
          userNum: el.userNum,
          addonsSelected: el.addonsSelected.map((el) => ({
            addonId: el.addonId,
            addonOptionId: el.addonOptionId,
          })),
        })),
      },
    };

    fetch(`${APIbackend}/v1/reservationsUpdates/parameters/change`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.status !== 200) {
          this.setState(
            {
              firstMessage: "Dane uczestnika nie zostały zapisane",
              colorMessage: "red",
              insuranceMessage: true,
            },
            () => window.scrollTo(0, 0)
          );
        }
        if (response.status === 200) {
          this.setState(
            {
              insuranceConfig: this.state.insuranceConfig,
              insuranceDetails: false,
            },
            () => window.scrollTo(0, 0)
          );

          if (responseData.ChangesFound && responseData.ChangesSaved && responseData.ChangesValidated) {
            fetch(`${APIbackend}/v1/account/reservations`, {
              method: "POST",
              body: JSON.stringify({
                siteId: this.state.siteId,
              }),
              credentials: "include",
            }).then((response) => {
              response.json().then((data) => {
                this.setState(
                  {
                    Reservations: data.Current,
                  },
                  () =>
                    setTimeout(() => {
                      const reservationArr2 = data.Current.find((el) => el.ReservationId === this.state.reservationId);
                      const lengthOptionsSelected = reservationArr2.OptionsSelected[this.state.userNum].length;
                      this.setState({
                        firstMessage: "Dane zostały poprawnie zapisane",
                        colorMessage: "green",
                        insuranceMessage: true,
                        insuranceDetails: false,
                        insuranceConfig: this.state.insuranceConfig,
                        secondMessage:
                          reservationArr2.OptionsSelected[this.state.userNum][lengthOptionsSelected - 1].Description,
                      });
                    }, 500)
                );
              });
            });
          } else if (!responseData.ChangesFound || !responseData.ChangesSaved || !responseData.ChangesValidated) {
            this.setState({
              firstMessage: "Dane uczestnika nie zostały zapisane.",
              colorMessage: "red",
              insuranceDetails: false,
              insuranceMessage: true,
            });
          }
        }
      });
    });
  };

  handleSubmitParametersAddons = (participantNumber, event) => {
    event.preventDefault();
    this.setState({
      userNum: participantNumber,
    });

    var data = {
      siteId: this.state.siteId,
      reservationId: this.state.reservationId,
      data: {
        journeyConfig: this.state.journeyConfig.map((el) => ({
          userNum: el.userNum,
          journeyId: el.journeyId,
          journeyPointId: el.journeyPointId,
        })),
        accommodationConfig: this.state.accommodationConfig.map((el) => ({
          userNum: el.userNum,
          roomId: el.roomId,
          optionsChosen: el.optionsChosen.map((el) => ({
            bedroomChosen: el.bedroomChosen,
          })),
        })),
        insuranceConfig: this.state.insuranceConfig.map((el) => ({
          userNum: el.userNum,
          insuranceId: el.insuranceId,
          quarantineAssistanceAccepted: el.quarantineAssistanceAccepted
        })),
        trainingsConfig: this.state.trainingsConfig.map((el) => ({
          userNum: el.userNum,
          trainingsSelected: [],
        })),
        addonsConfig: this.state.addonsConfig.map((el) => ({
          userNum: el.userNum,
          addonsSelected: el.addonsSelected.map((el) => ({
            addonId: el.addonId,
            addonOptionId: el.addonOptionId,
          })),
        })),
      },
    };

    fetch(`${APIbackend}/v1/reservationsUpdates/parameters/change`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.status !== 200) {
          this.setState(
            {
              firstMessage: "Dane uczestnika nie zostały zapisane",
              colorMessage: "red",
              addonsMessage: true,
            },
            () => window.scrollTo(0, 0)
          );
        }
        if (response.status === 200) {
          this.setState(
            {
              addonsConfig: this.state.addonsConfig,
              addonsDetails: false,
            },
            () => window.scrollTo(0, 0)
          );

          if (responseData.ChangesFound && responseData.ChangesSaved && responseData.ChangesValidated) {
            fetch(`${APIbackend}/v1/account/reservations`, {
              method: "POST",
              body: JSON.stringify({
                siteId: this.state.siteId,
              }),
              credentials: "include",
            }).then((response) => {
              response.json().then((data) => {
                this.setState(
                  {
                    Reservations: data.Current,
                  },
                  () =>
                    setTimeout(() => {
                      const reservationArr2 = data.Current.find((el) => el.ReservationId === this.state.reservationId);
                      const lengthOptionsSelected = reservationArr2.OptionsSelected[this.state.userNum].length;
                      this.setState({
                        firstMessage: "Dane zostały poprawnie zapisane",
                        colorMessage: "green",
                        addonsMessage: true,
                        addonsDetails: false,
                        addonsConfig: this.state.addonsConfig,
                        secondMessage:
                          reservationArr2.OptionsSelected[this.state.userNum][lengthOptionsSelected - 1].Description,
                      });
                    }, 500)
                );
              });
            });
          } else if (!responseData.ChangesFound || !responseData.ChangesSaved || !responseData.ChangesValidated) {
            this.setState({
              firstMessage: "Dane uczestnika nie zostały zapisane.",
              colorMessage: "red",
              addonsDetails: false,
              addonsMessage: true,
            });
          }
        }
      });
    });
  };

  handleSubmitParameters = (participantNumber, event) => {
    event.preventDefault();
    this.setState({
      userNum: participantNumber,
    });
    const reservationArr = this.state.Reservations.find((el) => el.ReservationId === this.state.reservationId);
    const lengthOptionsSelected = reservationArr.OptionsSelected[1].length;

    var data = {
      siteId: this.state.siteId,
      reservationId: this.state.reservationId,
      data: {
        journeyConfig: this.state.journeyConfig.map((el) => ({
          userNum: el.userNum,
          journeyId: el.journeyId,
          journeyPointId: el.journeyPointId,
        })),
        accommodationConfig: this.state.accommodationConfig.map((el) => ({
          userNum: el.userNum,
          roomId: el.roomId,
          optionsChosen: el.optionsChosen.map((el) => ({
            bedroomChosen: el.bedroomChosen,
          })),
        })),
        insuranceConfig: this.state.insuranceConfig.map((el) => ({
          userNum: el.userNum,
          insuranceId: el.insuranceId,
          quarantineAssistanceAccepted: el.quarantineAssistanceAccepted
        })),
        trainingsConfig: this.state.trainingsConfig.map((el) => ({
          userNum: el.userNum,
          trainingsSelected: [],
        })),
        addonsConfig: this.state.addonsConfig.map((el) => ({
          userNum: el.userNum,
          addonsSelected: el.addonsSelected.map((el) => ({
            addonId: el.addonId,
            addonOptionId: el.addonOptionId,
          })),
        })),
      },
    };

    fetch(`${APIbackend}/v1/reservationsUpdates/parameters/change`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) => {
      response.json().then((responseData) => {
        const reservationArr = this.state.Reservations.find((el) => el.ReservationId === this.props.reservationId);
        if (response.status !== 200) {
          this.setState(
            {
              firstMessage: "Dane uczestnika nie zostały zapisane",
              colorMessage: "red",
              transportMessage: true,
              accommodationMessage: true,
            },
            () => window.scrollTo(0, 0)
          );
        }
        if (response.status === 200) {
          this.setState(
            {
              journeyConfig: this.state.journeyConfig,
              accommodationConfig: this.state.accommodationConfig,
              addonsConfig: this.state.addonsConfig,
              accommodationDetails: false,
              transportDetails: false,
            },
            () => window.scrollTo(0, 0)
          );

          if (responseData.ChangesFound && responseData.ChangesSaved && responseData.ChangesValidated) {
            fetch(`${APIbackend}/v1/account/reservations`, {
              method: "POST",
              body: JSON.stringify({
                siteId: this.state.siteId,
              }),
              credentials: "include",
            }).then((response) => {
              response.json().then((data) => {
                this.setState({
                  Reservations: data.Current,
                });
              });
            });

            this.setState({
              firstMessage: "Dane zostały poprawnie zapisane",
              colorMessage: "green",
              transportMessage: true,
              transportDetails: false,
              accommodationMessage: true,
              journeyConfig: this.state.journeyConfig,
              accommodationConfig: this.state.accommodationConfig,
              secondMessage: reservationArr.OptionsSelected[1][lengthOptionsSelected - 1].Description,
            });
          } else if (!responseData.ChangesFound || !responseData.ChangesSaved || !responseData.ChangesValidated) {
            this.setState({
              firstMessage: "Dane uczestnika nie zostały zapisane.",
              colorMessage: "red",
              transportDetails: false,
              transportMessage: true,
              accommodationMessage: true,
            });
          }
        }
      });
    });
  };

  handleChangeVoucher = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  showLogOut = () => {
    this.setState({
      showPopup: true,
      panelLog: false,
      messagesActive: false,
      yourtripsActive: false,
      vouchersActive: false,
      ambasadorsActive: false,
      passwordActive: false,
      yourdetailsActive: false,
      logoutActive: true,
    });
  };

  closePopup = () => {
    this.setState({
      showPopup: false,
      yourtripsActive: true,
      panelLog: true,
      tourDetails: false,
      logoutActive: false,
    });
  };

  handleClick = (id) => {
    this.setState({
      yourtripsActive: false,
      tourDetails: true,
      reservationId: id,
    });
  };

  showDetailsLastTrip = (id) => {
    this.setState({
      yourtripsActive: false,
      lastTourDetails: true,
      reservationId: id,
    });
  };

  showYourTrips = () => {
    this.setState({
      yourtripsActive: true,
      messagesActive: false,
      tourDetails: false,
      errorMessageParametersGetAddons: "",
      errorMessageParametersGetAccommodation: "",
      errorMessageParametersGetInsurance: "",
      errorMessageParametersGetParticipants: "",
      errorMessageParametersGetTransport: "",
      messageNotReadyInvoiceDownload: "",
      messageNotReadyContractDownload: "",
      messageVoucher: "",
      trueMessage: "",
      errorMessage: "",
    });
  };

  componentDidMount = () => {
    fetch(`${APIbackend}/v1/auth/details`, {
      method: "GET",
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          this.setState({
            isAmbassador: data.Auth.IsAmbassador,
            hasNewMessages: data.Auth.HasNewMessages,
          });
        }
      });
    });

    fetch(`${APIbackend}/v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState({
          isLoading: false,
          Reservations: data.Current,
          ReservationHistory: data.Historic,
        });
      });
    });

    fetch(`${APIbackend}/v1/account/messages`, {
      method: "POST",
      body: JSON.stringify({siteId: this.state.siteId}),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState({
          MessagesArr: data.Messages,
        });
      });
    });
  };

  handleDeleteAccount = (event) => {
    event.preventDefault();
    var data = {
      siteId: this.state.siteId,
    };

    fetch(`${APIbackend}/v1/account/delete`, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => {
        response.json().then(() => {
          if (response.status !== 400) {
            this.setState({});
          }
          if (response.status === 200) {
            this.setState({});
          }
        });
      })
      .then(function (data) {});
  };

  handleLogoutClick = () => {
    fetch(`${APIbackend}/v1/auth/logout`, {
      method: "GET",
      credentials: "include",
    }).then((response) => {
      response.json().then(() => {
        if (response.status === 200) {
          window.location.href = "/";
        }
      });
    });
  };

  handleSubmitVoucher = () => {
    fetch(`${APIbackend}/v1/rebates/voucher/redeem`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
        voucherCode: this.state.voucherCode,
        userNum: 1,
      }),
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.status === 200) {
          fetch(`${APIbackend}/v1/account/reservations`, {
            method: "POST",
            body: JSON.stringify({
              siteId: this.state.siteId,
            }),
            credentials: "include",
          }).then((response) => {
            response.json().then((data) => {
              this.setState(
                {
                  Reservations: data.Current,
                },
                () =>
                  setTimeout(() => {
                    this.setState({
                      messageVoucher: "Voucher został naliczony jako wpłata ",
                      colorMessage: "green",
                      voucherCode: "",
                    });
                  }, 500)
              );
            });
          });
        }

        if (responseData.message === "Voucher of this type cannot be redeemed after reservation is completed") {
          this.setState({
            messageVoucher: "Nieprawidłowy typ vouchera. Voucher zniżkowy może zostać użyty tylko podczas rezerwacji.",
            colorMessage: "red",
          });
        } else if (response.status !== 200) {
          this.setState({
            messageVoucher: "Kod vouchera jest nieprawidłowy",
            colorMessage: "red",
          });
        }
      });
    });
  };

  showMessages = () => {
    this.setState({
      messagesActive: true,
      yourtripsActive: false,
      vouchersActive: false,
      ambasadorsActive: false,
      passwordActive: false,
      yourdetailsActive: false,
      logoutActive: false,
    });
  };

  showVouchers = () => {
    this.setState({
      messagesActive: false,
      yourtripsActive: false,
      vouchersActive: true,
      ambasadorsActive: false,
      passwordActive: false,
      yourdetailsActive: false,
      logoutActive: false,
    });
  };

  showAmbasadorCodes = () => {
    this.setState({
      messagesActive: false,
      yourtripsActive: false,
      vouchersActive: false,
      ambasadorsActive: true,
      passwordActive: false,
      yourdetailsActive: false,
      logoutActive: false,
    });
  };

  showChangePassword = () => {
    this.setState({
      messagesActive: false,
      yourtripsActive: false,
      vouchersActive: false,
      ambasadorsActive: false,
      passwordActive: true,
      yourdetailsActive: false,
      logoutActive: false,
    });
  };

  showYourDetails = () => {
    this.setState({
      messagesActive: false,
      yourtripsActive: false,
      vouchersActive: false,
      ambasadorsActive: false,
      passwordActive: false,
      yourdetailsActive: true,
      showlogoutActivepop: false,
    });
  };

  render() {
    const {
      Reservations,
      logoutActive,
      messagesActive,
      vouchersActive,
      ambasadorsActive,
      passwordActive,
      yourdetailsActive,
      userPanelActive,
      ReservationHistory,
      isAmbassador,
      reservationId,
      panelLog,
      MessagesArr,
      hasNewMessages,
      showPopup,
    } = this.state;

    const {
      showYourTrips,
      showLogOut,
      showVouchers,
      showAmbasadorCodes,
      showYourDetails,
      showChangePassword,
      handleDeleteAccount,
      handleLogoutClick,
      closePopup,
      showMessages,
    } = this;

    const allReservations = Reservations.concat(ReservationHistory);
    const reservationArr = allReservations.find(({ReservationId}) => ReservationId === reservationId);
    const isRedirectToPanel =
      !logoutActive && !messagesActive && !vouchersActive && !ambasadorsActive && !passwordActive && !yourdetailsActive;

    const tabsArrayWithAmb = [
      {
        keyIndex: "1",
        tabFor: "one",
        tabName: "Twoje Wyjazdy",
        tabMethod: showYourTrips,
        available: true,
      },
      {
        keyIndex: "2",
        tabFor: "two",
        tabName: "Wiadomości",
        tabMethod: showMessages,
        available: true,
      },
      {
        keyIndex: "3",
        tabFor: "three",
        tabName: "Vouchery",
        tabMethod: showVouchers,
        available: true,
      },
      {
        keyIndex: "4",
        tabFor: "four",
        tabName: "Panel Ambasadora",
        tabMethod: showAmbasadorCodes,
        available: isAmbassador,
      },
      {
        keyIndex: "5",
        tabFor: "five",
        tabName: "Twoje dane",
        tabMethod: showYourDetails,
        available: true,
      },
      {
        keyIndex: "6",
        tabFor: "six",
        tabName: "Zmień hasło",
        tabMethod: showChangePassword,
        available: true,
      },
      {
        keyIndex: "7",
        tabFor: "seven",
        tabName: "Wyloguj",
        tabMethod: showLogOut,
        available: true,
      },
    ];

    const tabsArrayInPanel = tabsArrayWithAmb?.filter(({available}) => available);

    return (
      <>
        <Router>
          <Switch>
            {isRedirectToPanel && <Redirect to="/twoj-panel" />}
            {messagesActive && <Redirect to="/twoj-panel/wiadomosci" />}
            {ambasadorsActive && <Redirect to="/twoj-panel/panel-ambasadora" />}
            {vouchersActive && <Redirect to="/twoj-panel/vouchery" />}
            {yourdetailsActive && <Redirect to="/twoj-panel/twoje-dane" />}
            {passwordActive && <Redirect to="/twoj-panel/zmiana-hasla" />}
            {logoutActive && <Redirect to="/twoj-panel/wyloguj" />}
          </Switch>
        </Router>
        <Helmet>
          <title>Panel klienta</title>
          <meta
            name="description"
            content="Tutaj sprawdzisz swoje dane i szczegółowe informacje odnośnie aktualnych rezerwacji."
            data-react-helmet="true"
          />
        </Helmet>
        {userPanelActive && (
          <div id="panelMenu">
            <Tabs defaultTab={panelLog ? "vertical-tab-one" : "vertical-tab-seven"} vertical>
              <TabList>
                {tabsArrayInPanel?.map(({tabFor, tabName, tabMethod, keyIndex}, index) => (
                  <Tab
                    key={keyIndex}
                    tabFor={`vertical-tab-${tabFor}`}
                    onClick={tabMethod}
                  >
                    {tabName}
                    {tabName === "Wiadomości" && (
                      <div className="led-box">
                        <div
                          className={
                            hasNewMessages || MessagesArr.find(({WasRead}) => !WasRead) ? "led-red" : "led-box"
                          }
                        ></div>
                      </div>
                    )}
                    <i className="arrow right" />
                  </Tab>
                ))}
              </TabList>
              <div className="panelBox">
                <TabPanel key="1" tabId="vertical-tab-one">
                  <YourTrips {...this.state} {...this} reservationArr={reservationArr} />
                </TabPanel>
                <TabPanel key="2" tabId="vertical-tab-two">
                  <div>
                    <Messages messagesActive={messagesActive} Reservations={Reservations} Messages={MessagesArr} />
                  </div>
                </TabPanel>
                <TabPanel key="3" tabId="vertical-tab-three">
                  <div>
                    <Vouchers vouchersActive={vouchersActive} Reservations={Reservations} />
                  </div>
                </TabPanel>
                {isAmbassador && (
                  <TabPanel key="4" tabId="vertical-tab-four">
                    <div>
                      <Ambassador ambasadorsActive={ambasadorsActive} Reservations={Reservations} />
                    </div>
                  </TabPanel>
                )}
                <TabPanel key="5" tabId="vertical-tab-five">
                  <div>
                    <YourDetails
                      yourdetailsActive={yourdetailsActive}
                      handleDeleteAccount={handleDeleteAccount}
                      Reservations={Reservations}
                    />
                  </div>
                </TabPanel>
                <TabPanel key="6" tabId="vertical-tab-six">
                  <div>
                    <ChangePassword passwordActive={passwordActive} Reservations={Reservations} />
                  </div>
                </TabPanel>
                <TabPanel key="7" tabId="vertical-tab-seven">
                  <div>
                    <LogOut
                      closePopup={closePopup}
                      logoutActive={logoutActive}
                      handleLogoutClick={handleLogoutClick}
                      showLogOut={showLogOut}
                      showPopup={showPopup}
                    />
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        )}
      </>
    );
  }
}

export default PanelMenu;
