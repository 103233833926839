import React from "react";
import "./Contact.scss";
import {Link} from "react-router-dom";
import APIbackend from "./config.js";

class Contact extends React.Component {
  state = {
    email: "",
    truemessage: "",
    errormessage: "",
    contents: "",
  };

  handleSubmit = (event) => {
    event.preventDefault();

    var data = {
      siteId: 1,
      email: this.state.email,
      contents: this.state.contents,
    };

    fetch(`${APIbackend}/v1/contact/request`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      response.json().then(() => {
        if (response.status === 200) {
          this.setState({
            errormessage: "",
            truemessage: "Wiadomość została wysłana",
          });
        }
        if (response.status !== 200) {
          this.setState({
            errormessage: "Uzupełnij wszystkie pola",
            truemessage: "",
          });
        }
      });
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const {email, truemessage, errormessage} = this.state;
    return (
      <div id="contactContainer">
        <div className="wrapper">
          <div className="box">
            <h1>Masz pytania?</h1>
            <div className="contact">
              <p>info@theboattrip.eu</p>
              <p>+48 577 543 094 </p>
            </div>
            <div className="adress">
              <p>ul. Wołodyjowskiego 40</p>
              <p>02-697 Warszawa</p>
            </div>
            <div className="openingHours">
              <p>Poniedziałek - piątek 10.00 - 15.00</p>
            </div>
            <Link to="/faq" title="Sprawdź FAQ">
              <button className="secondaryBtn">Sprawdź FAQ</button>
            </Link>
          </div>
          <div className="form">
            <form>
              <label>Twój adres e-mail</label>
              <input
                name="email"
                type="email"
                placeholder="Twój adres e-mail"
                onChange={this.handleChange}
                value={email}
              ></input>
              <label>Treść wiadomości</label>
              <textarea
                name="contents"
                onChange={this.handleChange}
                placeholder="W czym możemy Ci pomóc?"
                rows="8"
                cols="50"
              ></textarea>
              <button type="submit" className="primaryBtn" onClick={this.handleSubmit} title="Wyślij wiadomość">
                Wyślij wiadomość
              </button>
            </form>
            <p>
              <span className="trueMessage">{truemessage} </span>
              <span style={{fontSize: "14px"}} className="errorMessage">
                {errormessage}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
