import React from "react";
import "./BlogPage.scss";
import PartnersWrapper from "./PartnersWrapper.js";
import {connect} from "react-redux";
import {blogListCachedUrl, blogCachedUrl} from "./cachedStoreUrls";
import {fetchPosts, fetchData} from "./store/actions";
import Loader from "./Loader.js";
import { Link } from 'react-router-dom';
import logo from './img/logo-TBT.png';
import {Helmet} from "react-helmet";

const prefetchMethod = (dispatch) => {
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: "blog",
      },
      blogCachedUrl
    )
  );
};

const getBlogPosts = async (dispatch, endCursor) => {
  return dispatch(
    await fetchPosts(
      "",
      blogListCachedUrl,
      endCursor
    )
  );
};
const enhance = connect((state) => {
  return {
    data: state.fetchedUrls[blogListCachedUrl],
    dataLoaded: state.urlStatus[blogListCachedUrl] === "done",
    Page: state.fetchedUrls[blogCachedUrl]?.Page || {},
    dataPageLoaded: state.urlStatus[blogCachedUrl] === "done",
  };
});
class BlogPage extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    prefetchMethod(this.props.dispatch);
    getBlogPosts(this.props.dispatch)
  };

  loadMorePosts = async (endCursor) => {
    getBlogPosts(this.props.dispatch, endCursor)
  }

  render() {
    const { dataLoaded, data, dataPageLoaded, Page } = this.props;
    const metaData = Page?.META;

    if (!dataLoaded || !dataPageLoaded) {
      return (
        <Loader />
      );
    }
    return (
      <div className="blog">
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta name="description" content={metaData?.Description} data-react-helmet="true" />
        </Helmet>
        <div className="content">
            <div className="mainText">
              <h2>Lista artykułów:</h2>
            </div>
            <>
              {data?.edges?.map((el) => {
                return (
                  <Link id="blogPost" to={`/blog/${el.node?.slug}`}>
                      <div className="photoContainer">
                        {el?.node?.featuredImage?.node?.sourceUrl
                        ? (
                            <img src={el?.node?.featuredImage?.node?.sourceUrl} alt={el?.node?.featuredImage?.node?.alt} />
                        ) : (
                          <div className="logoContainer">
                            <img src={logo} alt={el.node?.title}/>
                          </div>
                        )
                    }</div>
                      
                    <div className="blogInfo">
                      <h5 dangerouslySetInnerHTML={{__html: el.node?.title}} />
                      <div className="blogDate">{new Date(el.node?.date).toLocaleDateString()}</div>
                      <div dangerouslySetInnerHTML={{__html: el.node?.excerpt}} />
                    </div>
                  </Link>
                )
              })}
            </>
            {data?.pageInfo.hasNextPage && (<button className="secondaryBtn" onClick={() => this.loadMorePosts(data?.pageInfo.endCursor)}>Więcej</button>)}
        </div>
        
        <PartnersWrapper />
      </div>
    );
  }
}

BlogPage.prefetchMethod = prefetchMethod;

export default enhance(BlogPage);
