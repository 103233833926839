import React from "react";
import "./Footer.scss";
import FooterColumn from "./FooterColumn.js";
import politykaPrywatnosci from "./userRegistration/documents/polityka-prywatnosci.pdf";
import regulaminTaksidi from "./userRegistration/documents/regulamin-taksidi.pdf";
import logoFooter from "./img/logo-TBT.png";
import logoInfrastrukturaSrodowisko from "./img/FE_Inrastruktura_i_Srodowisko.png";
import pfr from "./img/plansza_informacyjna_PFR_poziom.png";

var CruisesArr = [
  {
    title: "Rejsy",
    link: "/rejsy-morskie",
    titleAttribute: "Rejsy morskie",
  },

  {
    title: "Rejsy mazurskie",
    link: "/mazury",
    titleAttribute: "Rejsy mazurskie",
  },

  {
    title: "Wyjazdy firmowe",
    link: "/wyjazdy-firmowe",
    titleAttribute: "Wyjazdy firmowe na rejsy",
  },
  {
    title: "Rejsy prywatne",
    link: "/rejsy-prywatne",
    titleAttribute: "Rejsy morskie - prywatne",
  },

  {
    title: "Szkolenia",
    link: "/szkolenia",
    titleAttribute: "Szkolenia żeglarskie",
  },
];

const HowItWorksArr = [
  {
    title: "Info ogólne",
    link: "/jak-to-dziala",
    hashLink: "#ogolne",
    titleAttribute: "Informacje ogólne",
  },
  {
    title: "Wybór rejsu",
    link: "/jak-to-dziala",
    hashLink: "#jak-wybrac-rejs",
    titleAttribute: "Wybór rejsu",
  },
  {
    title: "Wybór jachtu",
    link: "/jak-to-dziala",
    hashLink: "#jak-wybrac-jacht",
    titleAttribute: "Wybór jachtu",
  },
  {
    title: "Proces zapisu",
    link: "/jak-to-dziala",
    hashLink: "#jak-sie-zapisac-na-rejs",
    titleAttribute: "Proces zapisu na rejs",
  },
  {
    title: "Co jest w cenie",
    link: "/jak-to-dziala",
    hashLink: "#platnosci-i-cena",
    titleAttribute: "Płatności za rejs",
  },
];

var AboutUsArr = [
  {
    title: "O nas",
    link: "/o-nas",
    titleAttribute: "O firmie The Boat Trip",
  },
  {
    title: "Nasz zespół",
    link: "/o-nas/team",
    titleAttribute: "Nasz zespół The Boat Trip",
  },
  {
    title: "Praca",
    link: "/o-nas/praca",
    titleAttribute: "Praca w The Boat Trip",
  },
  {
    title: "Dokumenty",
    link: "/o-nas/dokumenty",
    titleAttribute: "Dokumenty do pobrania",
  },
];

const Footer = () => {
  return (
      <div id="footerContainer">
        <div className="footerMenu">
          <div className="top">
            <div className="imageLogo">
              <img src={logoFooter} alt="logo The Boat Trip" />
            </div>
            <div className="socialMediaContainer">
              <p>Obserwuj Nas!</p>
              <div className="icons">
                <a
                  href="https://www.facebook.com/TheBoatTrip"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Sprawdź naszego facebooka | The Boat Trip"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a
                  href="https://www.instagram.com/TheBoatTrip"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Sprawdź naszego instagrama | The Boat Trip "
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.youtube.com/user/TheBoatTripEU"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Sprawdź nasz kanał na youtube | The Boat Trip"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="oneFooterBox" id="howItWorksColumn">
              <h5>Jak to działa?</h5>
              {HowItWorksArr.map(({link, title, hashLink, titleAttribute}, index) => (
                <FooterColumn
                  key={index}
                  link={link}
                  title={title}
                  hashLink={hashLink}
                  titleAttribute={titleAttribute}
                />
              ))}
            </div>
            <div className="oneFooterBox" id="whatWeDoColumn">
              <h5>Co robimy?</h5>
              {CruisesArr.map(({link, titleAttribute, title}, index) => (
                <FooterColumn key={index} link={link} title={title} titleAttribute={titleAttribute} />
              ))}
            </div>

            <div className="oneFooterBox" id="aboutUsColumn">
              <h5>Kim jesteśmy?</h5>
              {AboutUsArr.map(({link, title, titleAttribute}, index) => (
                <FooterColumn key={index} link={link} title={title} titleAttribute={titleAttribute} />
              ))}
            </div>
            <div className="oneFooterBox">
              <h5>Licencja</h5>
              <p>Numer licencji organizatora turystyki 1163</p>
              <h5>Kontakt</h5>
              <p className="contactLink">info@theboattrip.eu</p>
              <p className="contactNumber">+48 577 543 094</p>
            </div>
          </div>
        </div>
        <div className="footerContact">
          <div className="container"></div>
          <div className="footerBottom">
            <div className="text">
              <p className="copyRight">
                <a
                  href={politykaPrywatnosci}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Polityka prywatności The Boat Trip"
                >
                  Polityka prywatności<i className="arrow"></i>
                </a>
              </p>
              <p className="copyRight">
                <a
                  href={regulaminTaksidi}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Sprwdź regulamin strony The Boat Trip"
                >
                  Regulamin strony<i className="arrow"></i>
                </a>
              </p>
              <p className="copyRight">© 2010-2025 Copyright Taksidi</p>
            </div>
            <div className="logo">
              <img src={logoInfrastrukturaSrodowisko} alt="Logo" />
            </div>

            <p className="copyRight2">© 2010-2025 Copyright Taksidi</p>
          </div>
          <div className="pfrContainer">
            <img src={pfr} alt="PFR" />
          </div>
        </div>
      </div>
  );
};

export default Footer;
