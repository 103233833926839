import React from "react";
import "./Discounts.scss";
import {Link, Element, Events, animateScroll as scroll, scrollSpy} from "react-scroll";
import ReactHtmlParser from "react-html-parser";
import {Link as LinkSecond} from "react-router-dom";
import imageDiscountOne from "./img/discount-image2.jpg";
import imageDiscountTwo from "./img/discount-image3.jpg";
import {discountCachedUrl} from "./cachedStoreUrls";
import {fetchData} from "./store/actions";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import Loader from "./Loader.js";

const PromotionsArr = [
  {
    index: 1,
    image: imageDiscountOne,
    name: "Zniżki",
    description: "Zgarnij rabat na wyjazd",
    number: 3,
    button: "Sprawdż zniżki",
    bg: "rgba(6, 216, 86, 0.27)",
    nameID: "discountsDescription",
    altImage: "Zniżki na rejsy morskie",
    title: "Sprawdź zniżki na rejsy",
  },

  {
    index: 2,
    image: imageDiscountTwo,
    name: "Promocje",
    description: "Sprawdź co ekstra można dostać",
    number: 1,
    button: "Zobacz promocje",
    bg: "rgba(146, 2, 31, 0.39)",
    nameID: "promotionDescription",
    altImage: "Promocje na rejsy morskie",
    title: "Zobacz promocje na rejsy",
  },

  // {
  //     index: 3,
  //     image: imageDiscountThree,
  //     name: 'Nasi Partnerzy',
  //     description: "Sprawdź oferty naszych partnerów",
  //     number: 1,
  //     button: "Skorzystaj",
  //     bg: "rgba(6, 172, 216, 0.31)",
  //     nameID: "partnersDescription",
  //     altImage: "Nasi partnerzy",
  //     title: "Nasi partnerzy "
  // }
];

const DiscountsArray = [
  {
    name: "Zniżki",
    nameID: "discountsDescription",
    textArr: [
      {
        index: 1,
        name: "First Minute",
        discount: "300",
        title: "Zniżka First Minute",
        filter: "rgba(6, 216, 86, 0.57)",
        list: [
          {subItem: "Nominalny rabat First Minute 200 PLN"},
          {
            subItem: "FIRST MINUTE łączy się z innymi zniżkami do kwoty 300 PLN",
          },
          {
            subItem: "Gwarantujemy możliwość BEZPŁATNEJ REZYGNACJI do 30 dni przed terminem rejsu",
          },
          {subItem: "Promocja trwa do 28 lutego 2021"},
        ],
        paragraphs: [{}],
        textButton: "Sprawdź nasze rejsy",
      },

      {
        index: 2,
        name: "Second Minute",
        discount: "200",
        title: "Zniżka Second Minute",
        filter: "rgba(6, 216, 86, 0.57)",
        list: [
          {subItem: "Nominalny rabat Second Minute 100 PLN"},
          {
            subItem: "Second Minute łączy się z innymi zniżkami do kwoty 200 PLN",
          },
          {
            subItem: "Gwarantujemy możliwość BEZPŁATNEJ REZYGNACJI do 30 dni roboczych przed terminem rejsu",
          },
          {subItem: "Promocja trwa do 14 marca 2021"},
        ],
        paragraphs: [{}],
        textButton: "Sprawdź nasze rejsy",
      },

      {
        index: 3,
        name: "Zniżka grupowa",
        discount: "120",
        title: "Zniżka grupowa",
        filter: "rgba(6, 216, 86, 0.57)",
        paragraphs: [
          {
            text: "Chcecie zapisać się na wyjazd ekipą? Proponujemy zorganizowane grupy!",
          },
          {
            text: "Zniżkę grupową otrzymuje od nas każdy członek zadeklarowanej grupy. Wystarczy, że jedna osoba z Waszej ekipy zapisze się pierwsza i wygeneruje specjalny kod, a kolejne osoby powołają się na ten kod przy swoich rezerwacjach, a zniżka naliczy Wam się automatycznie. UWAGA - raz wygenerowany kod jest ważny przez 10 dni kalendarzowych, po tym czasie można dołączyć do grupy, ale już tylko na zasadzie wspólnego zakwaterowania (bez zniżki).",
          },
        ],
        list: [
          {subItem: "6 - 8 osób – 40 PLN"},
          {subItem: "9 - 12 osób – 60 PLN"},
          {subItem: "13 - 15 osób – 80 PLN"},
          {subItem: "16 i więcej – 100 PLN"},
        ],
        textButton: "Sprawdź nasze rejsy",
      },

      {
        index: 4,
        name: "Zniżka lojalnościowa",
        discount: "100",
        title: "Zniżka lojalnościowa",
        filter: "rgba(6, 216, 86, 0.57)",
        paragraphs: [],
        list: [
          {subItem: "1 wyjazd – 40 PLN"},
          {subItem: "2 wyjazdy – 60 PLN"},
          {subItem: "3 wyjazdy – 80 PLN"},
          {subItem: "4 i więcej – 100 PLN"},
        ],

        textButton: "Sprawdź nasze rejsy",
      },

      {
        index: 5,
        name: "Kody ambasadora",
        discount: "",
      },
      {
        index: 6,
        name: "Zniżka na cale jachty",
        discount: "200",
      },
    ],
  },

  {
    name: "Promocje",
    description: "",
    nameID: "promotionDescription",
    textArr: [
      {
        name: "Pakiet Bezpieczna Rezerwacja",
        title: "Bezpieczna Rezerwacja",
        filter: "rgba(185, 4, 41, 0.69)",
        list: [
          {
            subItem: "opcje bezpłatnej rezygnacji do 30 dni roboczych przed wyjazdem",
          },
          {subItem: "100% zwrotu w przypadku zamknięcia granic"},
          {subItem: "niską zaliczkę w wysokości 100 zł"},
          {subItem: "opiekę zdrowotną w przypadku zachorowania na COVID-19"},
          {
            subItem:
              "ubezpieczenie od kosztów rezygnacji dla rejsów eurpejskich za jedyne 50 zł - dla rejsów egzotycznych za 100 zł (zamiast 200 zł)",
          },
        ],
        paragraphs: [],
        textButton: "Sprawdź nasze rejsy",
      },

      {
        name: "Zniżka na całe jachty",
        title: "Zniżka na całe jachty",
        filter: "rgba(185, 4, 41, 0.69)",

        paragraphs: [],
        textButton: "Sprawdź nasze rejsy",
      },

      {
        name: "Zniżki",
        title: "Zniżki",
        filter: "rgba(185, 4, 41, 0.69)",

        textButton: "Sprawdź nasze rejsy",
      },
    ],
  },
];

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: "discounts",
      },
      discountCachedUrl
    )
  );
};
const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls[discountCachedUrl]?.Page || {},
    dataLoaded: state.urlStatus[discountCachedUrl] === "done",
  };
});
class Discounts extends React.Component {
  state = {
    Page: {},
    loading: true,
  };

  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);

    Events.scrollEvent.register("begin", function () {});

    Events.scrollEvent.register("end", function () {});

    scrollSpy.update();
  };

  componentWillUnmount = () =>
    function () {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };

  scrollToTop = () => {
    scroll.scrollToTop();
  };
  scrollToBottom = () =>
    function () {
      scroll.scrollToBottom();
    };
  scrollTo = () =>
    function () {
      scroll.scrollTo(100);
    };
  scrollMore = () =>
    function () {
      scroll.scrollMore(100);
    };
  handleSetActive = () => function (to) {};

  render() {
    const {Page, dataLoaded} = this.props;
    const metaData = Page?.META;

    return !dataLoaded ? (
      <Loader />
    ) : (
      <div className="discounts">
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta name="description" content={metaData?.Description} data-react-helmet="true" />
        </Helmet>

        <div id="promotions">
          <div className="content">
            <div className="mainText">
              <h1>Sprawdź nasze promocje i zniżki na rejsy morskie w sezonie 2025/26</h1>
            </div>
            <div className="wrapper">
              {PromotionsArr.map((el) => (
                <div className="oneDiscountBox">
                  <div className="image">
                    <img src={el.image} alt={el.altImage} />
                  </div>
                  <div className="overlay" style={{background: el.bg}}></div>
                  <h3 className="name">{el.name}</h3>
                  {/* <div className="numberBox"><span className="number">{el.number}</span></div> */}
                  <div className="description">{el.descrption}</div>
                  <button className="primaryBtn ">
                    <Link
                      to={el.nameID}
                      spy={true}
                      smooth={true}
                      offset={50}
                      duration={500}
                      onSetActive={this.handleSetActive}
                      title={el?.title}
                    >
                      {el.button}
                    </Link>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Element id={DiscountsArray[0].nameID} className="element" key={DiscountsArray[0].nameID}>
          <div className="content">
            <div className="mainText">
              <h2>Zniżki</h2>
            </div>
            {Page.Contents !== undefined &&
              Page.Contents.filter(({Type}) => Type === "TextWithImages").map(({Contents}) => (
                <Element id="singleDiscount" name="singleDiscount" className="singleDiscount">
                  <div className="photoContainer">
                    <img src={Contents.Images[0]?.url} alt={Contents.Images[0]?.alt} />
                    <div className="overlay">
                      <div className="text">
                        {Contents.Buttons[0] && <p className="name">{ReactHtmlParser(Contents.Buttons[0])}</p>}
                        {Contents.Buttons[1] && <p className="discount">{ReactHtmlParser(Contents.Buttons[1])}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="tripInfo">
                    {ReactHtmlParser(Contents.Text)}
                    <LinkSecond to="/rejsy-morskie">
                      <button className="secondaryBtn">Sprawdź nasze rejsy</button>
                    </LinkSecond>
                  </div>
                </Element>
              ))}
          </div>
        </Element>

        <Element id={DiscountsArray[1].nameID} className="element" key={DiscountsArray[1].nameID}>
          <div className="content">
            <div className="mainText">
              <h2>Promocje</h2>
            </div>
            {Page.Contents !== undefined &&
              Page.Contents.filter(({Type}) => Type === "TextWithImages").map(({Contents}) =>
                Contents.Text.includes("Pakiet") ? (
                  <Element id="singleDiscount" name="singlePromotion" className="singlePromotion">
                    <div className="photoContainer">
                      <img src={Contents.Images[0]?.url} alt={Contents.Images[0]?.alt} />
                      <div className="overlay">
                        <div className="text">
                          {Contents.Buttons[0] && <p className="name">{ReactHtmlParser(Contents.Buttons[0])}</p>}
                          {Contents.Buttons[1] && <p className="discount">{ReactHtmlParser(Contents.Buttons[1])}</p>}
                        </div>
                      </div>
                    </div>
                    <div className="tripInfo">
                      {ReactHtmlParser(Contents.Text)}
                      <LinkSecond to="/rejsy-morskie" titlr="Sprawdź nasze rejsy">
                        <button className="secondaryBtn">Sprawdź nasze rejsy</button>
                      </LinkSecond>
                    </div>
                  </Element>
                ) : null
              )}
          </div>
        </Element>

        {/* <Element id={DiscountsArray[2].nameID} className="element" key={DiscountsArray[2].nameID}>
                    <div className="content">
                        <div className="mainText">
                            <h2>Partnerzy</h2>
                        </div>
                        {Page.Contents !== undefined && Page.Contents.filter(({ Type }) => Type === "TextWithImages").map(({ Contents }) => Contents.Text.includes("Flixbus") ?
                            <Element id='singleDiscount' name='singlePartner' className="singlePartner">
                                <div className="photoContainer">
                                    <img src={Contents.Images[0]} alt={""} />
                                    <div className="overlay">
                                        <div className="text">
                                            {Contents.Buttons[0] && <p className="name">{ReactHtmlParser(Contents.Buttons[0])}</p>}
                                            {Contents.Buttons[1] && <p className="discount">{ReactHtmlParser(Contents.Buttons[1])}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="tripInfo">
                                    {ReactHtmlParser(Contents.Text)}
                                    <LinkSecond to="/rejsy-morskie"> <button className="secondaryBtn">Sprawdź nasze rejsy</button> </LinkSecond>
                                </div>
                            </Element>
                            : null)}
                    </div>
                </Element> */}
        <button className="scrollTotop" onClick={this.scrollToTop}>
          To the top!
        </button>
      </div>
    );
  }
}

Discounts.prefetchMethod = prefetchMethod;

export default enhance(Discounts);
